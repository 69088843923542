import React from "react";
import { PanelButton } from "../MobileFixtures/FixturePanel";
import { BetFilter } from "./MobileBets";

interface MobileBetsFilterPanelProps {
  onFilterClick: (value: BetFilter) => void;
  currentFilters: BetFilter[];
  isDesktop?: boolean;
}

const MobileBetsFilterPanel: React.FC<MobileBetsFilterPanelProps> = ({
  onFilterClick,
  currentFilters,
  isDesktop
}) => {
  return (
    <div
      style={{
        height: "50px",
        padding: "20px",
        display: "flex",
        marginTop: "20px",
        justifyContent: "center",
        gap: "10px",
        
      }}
    >
      <PanelButton
        label="Ongoing"
        onClick={() => onFilterClick("Ongoing")}
        isActive={currentFilters.includes("Ongoing")}
        isDesktop={isDesktop}
      />
      <PanelButton
        label="Past"
        onClick={() => onFilterClick("Past")}
        isActive={currentFilters.includes("Past")}
        isDesktop={isDesktop}
      />
      <PanelButton
        label="Correct"
        onClick={() => onFilterClick("Correct")}
        isActive={currentFilters.includes("Correct")}
        isDesktop={isDesktop}
      />
      <PanelButton
        label="Wrong"
        onClick={() => onFilterClick("Wrong")}
        isActive={currentFilters.includes("Wrong")}
        isDesktop={isDesktop}
      />
    </div>
  );
};

export default MobileBetsFilterPanel;
