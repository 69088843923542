import "./BrowserNavbar.scss";
import { AppBar, Modal, TextField } from "@mui/material";
import {
  AccountCircle,
  Assignment,
  FormatBold,
  People,
  SportsSoccer,
  Star,
} from "@mui/icons-material";
import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../common/contexts/userContext";
import { API_URL } from "../../utils/api";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const BrowserNavbar = () => {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const { user } = useUser();
  const navigate = useNavigate();
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const [showLogoutModal, setShowLogoutModal] = React.useState<boolean>(false);
  const [showUpdateModal, setShowUpdateModal] = React.useState<boolean>(false);

  const handleCloseUserMenu = (route: string) => {
    if (route === "/logout") {
      setShowLogoutModal(true);
    } else if (route === "/data") {
      setShowUpdateModal(true);
    } else {
      if (routingArray.includes(route)) {
        navigate(route);
      }
    }
    setAnchorElUser(null);
  };
  function handleLogout() {
    setShowLogoutModal(false);
    window.localStorage.setItem("isSigned", "false");
    window.localStorage.setItem("accessToken", "");
    navigate("/login");
  }

  return (
    <AppBar position="static" className="BrowserNavbarLayout">
      <Container maxWidth="xl" style={{display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'space-between'}}>
        <Toolbar disableGutters>
          <img
            src="./images/logo_cropped.png"
            alt="phone and bets"
            style={{ height: "50px", marginRight: "10px" }}
          />
          <Typography
            variant="h5"
            noWrap
            component="a"
            // href="/homepage"
            sx={{
              mr: 5,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".2rem",
              color: "#EAEAEA",
              textDecoration: "none",
            }}
          >
            Typer2024
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {navigationProps.map((page) => (
              <Button
                startIcon={page.icon}
                key={page.name}
                onClick={() => navigate(page.routePath)}
                sx={{
                  my: 2,
                  color: "#E9EDED",
                  display: "flex",
                  mr: 2,
                  fontSize: "16px",
                  fontWeight: "600",
                  letterSpacing: "0.15em",
                  borderBottom: window.location.pathname.includes(
                    page.routePath
                  )
                    ? "1px solid #F9BF4B"
                    : undefined,
                }}
              >
                {page.name}
              </Button>
            ))}
          </Box>

         
        </Toolbar>
        <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="profile photo" src={user.avatarUrl}/>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem
                  key={setting.label}
                  onClick={() => handleCloseUserMenu(setting.routing)}
                  style={{ width: "140px" }}
                >
                  <Typography textAlign="center">{setting.label}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        <ModalLogout
          open={showLogoutModal}
          onClose={setShowLogoutModal}
          handleLogout={handleLogout}
        />
        <ModalUpdate open={showUpdateModal} onClose={setShowUpdateModal} />
      </Container>
    </AppBar>
  );
};
export default BrowserNavbar;

const navigationProps = [
  {
    name: "Bets",
    routePath: "/bets",
    icon: <FormatBold style={{ color: "#F9BF4B" }} />,
  },
  {
    name: "Fixtures",
    routePath: "/fixtures",
    icon: <SportsSoccer style={{ color: "#F9BF4B" }} />,
  },
  {
    name: "Ranking",
    routePath: "/ranking",
    icon: <Star style={{ color: "#F9BF4B" }} />,
  },
  {
    name: "Leagues",
    routePath: "/leagues",
    icon: <People style={{ color: "#F9BF4B" }} />,
    
  },
  {
    name: "Data",
    routePath: "/data",
    icon: <Assignment style={{ color: "#F9BF4B" }} />,
  },
  {
    name: "Profile",
    routePath: "/profile",
    icon: <AccountCircle style={{ color: "#F9BF4B" }} />,
  },

];
const settings = [
  { label: "Profile", routing: "/profile" },
  { label: "FAQ", routing: "/faq" },
  { label: "Update data", routing: "/data" },
  { label: "Logout", routing: "/logout" },
];

const routingArray = ["/profile", "/faq", "/logout"];

interface ModalLogoutProps {
  open: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  handleLogout: () => void;
}
export const ModalLogout: React.FC<ModalLogoutProps> = ({
  open,
  onClose,
  handleLogout,
}) => (
  <Modal
    open={open}
    onClose={() => onClose(false)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Do you really want to logout?
      </Typography>
      <Button
        id="modal-modal-description"
        sx={{ mr: 2, mt: 2 }}
        variant="outlined"
        color="warning"
        onClick={() => handleLogout()}
      >
        Yes
      </Button>
      <Button
        id="modal-modal-description"
        sx={{ mr: 2, mt: 2 }}
        variant="outlined"
        onClick={() => onClose(false)}
      >
        No
      </Button>
    </Box>
  </Modal>
);

interface ModalUpdateProps {
  open: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalUpdate: React.FC<ModalUpdateProps> = ({ open, onClose }) => {
  const { user, setUser } = useUser();
  const [username, setUsername] = React.useState(user.username);
  const [avatar, setAvatar] = React.useState(user.username);

  function handleName(event: React.ChangeEvent<HTMLInputElement>) {
    setUsername(event.target.value);
  }

  function handleUpdate() {
    const newUser = { email: user.email, username: username, id: user.id };
    const requestOption = {
      method: "PUT",
      body: JSON.stringify(newUser),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
      },
    };

    function correctFetch() {
      onClose(false);
      setUser({ ...user, username: username });
    }
    fetch(API_URL + `/api/User/${user.id}`, requestOption).then((response) =>
      response.ok ? correctFetch() : alert("something went wrong")
    );
  }
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const specialChars = [
      "Space",
      "Backquote",
      "Minus",
      "Equal",
      "BracketLeft",
      "BracketRight",
      "Backslash",
      "Semicolon",
      "Quote",
      "Slash",
      "Comma",
    ];

    if (specialChars.includes(event.code)) {
      event.preventDefault();
    }
  };
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h4" component="h1">
          Your data
        </Typography>
        <Box>
          <Typography> Your username </Typography>
          <TextField
            id="outlined-basic"
            label="name"
            variant="outlined"
            value={username}
            onChange={handleName}
            style={{ margin: "10px 0" }}
            onKeyDown={handleKeyDown}
          />
          <Typography> Your avatar </Typography>
          <Typography> Soon available ... </Typography>
        </Box>
        <Button
          id="modal-modal-description"
          sx={{ mr: 2, mt: 2 }}
          variant="outlined"
          color="warning"
          onClick={() => handleUpdate()}
        >
          Change my data
        </Button>
        <Button
          id="modal-modal-description"
          sx={{ mr: 2, mt: 2 }}
          variant="outlined"
          onClick={() => onClose(false)}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};
