import React, { useEffect, useState } from "react";
import { League } from "../../types/League";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LeagueViewTab } from "./MobileLeagues";
import { useUser } from "../../common/contexts/userContext";
import { API_URL } from "../../utils/api";
import { RankingData } from "../../types/RankingData";
import { useAllUsers } from "../../common/contexts/allUsersContext";

interface PersonalLeagueInfoProps {
  leagueInfo: League;
  setManageLeagueId: React.Dispatch<React.SetStateAction<number>>;
  setCurrentView: React.Dispatch<React.SetStateAction<LeagueViewTab>>;
  isAdmin: boolean;
}

async function fetchRows(leagueInfoId: number) {
  const token = window.localStorage.getItem("accessToken");
  const requestOption = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const data = await fetch(
    API_URL + `/api/LeagueRanking/${leagueInfoId}`,
    requestOption
  );
  return await data.json();
}

const PersonalLeagueInfo: React.FC<PersonalLeagueInfoProps> = ({
  leagueInfo,
  setCurrentView,
  setManageLeagueId,
}) => {
  const { user } = useUser();
  const { users } = useAllUsers();
  const isAdmin = leagueInfo.adminId === user.id;
  const admin = users.filter(item => item.id === leagueInfo.adminId)[0];
  const [dataRows, setDataRows] = useState<RankingData[]>();
  function handleManageLeague() {
    setManageLeagueId(leagueInfo.id);
    setCurrentView("ManageLeagues");
  }
  useEffect(() => {
    fetchRows(leagueInfo.id).then((data) => setDataRows(data.rankingRows));
  }, [leagueInfo]);
  return (
    <Accordion style={{ margin: "10px 0" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: "#FAFAFA" }} />}
        aria-controls="panel1-content"
        id="panel1-header"
        style={{ background: "#454758", color: "#FAFAFA" }}
      >
        <span style={{ fontSize: "20px", fontWeight: "600" }}>
          {leagueInfo.name}
        </span>
      </AccordionSummary>
      <AccordionDetails
        style={{
          background: "#656778",
          color: "#DEDFDD",
          paddingTop: "10px",
          display: "flex",
        }}
      >
        <div
          style={{
            width: "70%",
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            fontSize: "18px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "85%",
            }}
          >
            <Typography>Position</Typography>
            <Typography style={{ fontWeight: "600" }}>
              {dataRows?.filter((item) => item.userId === user.id)[0]?.rankPlace}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "85%",
            }}
          >
            <Typography>No. of players</Typography>
            <Typography style={{ fontWeight: "600" }}>
              {dataRows?.length}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "85%",
            }}
          >
            <Typography>Admin</Typography>
            <Typography style={{ fontWeight: "600" }}>
              {admin?.username}
            </Typography>
          </div>

          {isAdmin && (
            <Button
              style={{
                background: "#40E0D0",
                color: "#061237",
                textTransform: "none",
                fontWeight: "600",
                marginTop: "5px",
                width: "85%",
              }}
              onClick={handleManageLeague}
            >
              {" "}
              Manage League{" "}
            </Button>
          )}
        </div>
        <div
          style={{
            width: "30%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar
            src={leagueInfo.leagueImageUrl}
            style={{ height: "90px", width: "90px" }}
          />
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default PersonalLeagueInfo;
