import {
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
  Link,
  Divider,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { initialLoginValues } from "../../Mobile/MobileSign/MobileLoginPanel";
import { useUser } from "../../common/contexts/userContext";
import { useFixtures } from "../../common/contexts/fixturesContext";
import { useBets } from "../../common/contexts/betContext";
import { useNavigate } from "react-router-dom";
import "../BrowserStyles.scss";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { MobileAuthenticationPanel } from "../../Mobile/MobileSign/MobileAuthenticationPanel";
import { API_URL } from "../../utils/api";
import { Match } from "../../types/Match";

interface BrowserLoginProps { }

const BrowserLogin: React.FC<BrowserLoginProps> = () => {
  const [loginValues, setLoginValues] = useState(initialLoginValues);
  const [showPassword, setShowPassword] = useState(false);

  const { setUser } = useUser();
  const { setMatches } = useFixtures();
  const { setBets } = useBets();
  const navigate = useNavigate();
  const handleClickShowPassword = () =>
    setShowPassword((show: boolean) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  function handleChangeEmail(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setLoginValues((prev) => ({ ...prev, username: e.target.value }));
  }

  function handleChangePassword(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setLoginValues((prev) => ({ ...prev, password: e.target.value }));
  }

  async function handleLogin() {
    // Auth
    async function correctLogin(data: any) {
      // setlocal storage with token
      if(!data.isSucceed){
        alert("Incorrect credentials")
        return 
      }
      // console.log("DATA: ", data);
      
      // user context
      window.localStorage.setItem("accessToken", data.data.accessToken);
      window.localStorage.setItem("isSigned", "true");
      setUser(data.data.user);

      // Fixtures context
      const requestOptionFixtures = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.data.accessToken}`,
          // "Cache-Control": "no-store"
        },
      };
      const responseFixtures = await fetch(
        API_URL + `/api/Match/all`,
        requestOptionFixtures
      );
      const dataFixtures = await responseFixtures.json();
      // console.log(dataFixtures);

      setMatches(
        dataFixtures.filter((match: Match) => match.homeTeam && match.awayTeam)
      );
      // Bet Context
      const requestOptionBets = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.data.accessToken}`,
        },
      };
      const responseBets = await fetch(
        API_URL + `/api/Bet/All/User/${data.data.user.id}`, // #TODO get user ID
        requestOptionBets
      );
      const dataBets = await responseBets.json();
      setBets(dataBets);
      navigate("/profile");
    }
    const loginRequestOption = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: loginValues.username,
        password: loginValues.password,
      }),

    };

    await fetch(API_URL + "/api/Authorization/Login", loginRequestOption).then(
      async (data) =>
        data.ok 
          ? correctLogin(await data.json())
          : alert("Wrong credentials.") // alert("You entered wrong credentials")
    );
  }
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        overflow: "hidden",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        style={{
          height: "80%",
          width: "33%",
          borderRadius: "6%",
          minWidth: "400px",
          minHeight: "600px",
          margin: "2%",
          background: "#172245",
          border: "1px solid #F9BF8B",
        }}
      >
        <CardContent
          style={{
            color: "#EAEAEA",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <Typography variant="h3" style={{ margin: "20px 0" }}>
              Log in
            </Typography>
            <Typography
              style={{
                textAlign: "left",
                width: "100%",
                paddingLeft: "10px",
                margin: "10px 0",
              }}
            >
              {" "}
              Username
            </Typography>
            <TextField
              variant="outlined"
              style={{ width: "100%", marginBottom: "15px" }}
              InputProps={{ className: "customedInput borderRadiusInput" }}
              type="text"
              value={loginValues.username}
              onChange={handleChangeEmail}
              name="email"
            />
            <Typography
              style={{
                margin: "10px 0",
                width: "100%",
                textAlign: "left",
                paddingLeft: "10px",
              }}
            >
              {" "}
              Password{" "}
            </Typography>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              onChange={handleChangePassword}
              style={{ width: "100%" }}
              value={loginValues.password}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              className="customedInput outlinedCustomInput"
              name="password"
            />
            {/* <Link
              style={{
                textAlign: "end",
                marginRight: 10,
                marginTop: 5,
                width: "100%",
                color: "#00B0FF",
                textDecoration: "none",
              }}
            >
              Forgot password?
            </Link> */}
            <Button
              style={{
                borderRadius: "25px",
                width: "100%",
                height: "45px",
                padding: 0,
                color: "#111111",
                background: "#F9BF4B",
                marginTop: '45px',
                border: "1px black solid",
              }}
              onClick={handleLogin}
            >
              Log In
            </Button>
            <Divider
              style={{ margin: "30px 0", color: "#FFF", width: "100%" }}
              sx={{
                "&::before, &::after": {
                  bgcolor: "#A2AEBB",
                },
              }}
            >
              {" "}
              or{" "}
            </Divider>
            <MobileAuthenticationPanel/>
          </div>
          <Link
            style={{
              width: "100%",
              textAlign: "center",
              color: "#00B0FF",
              textDecoration: "none",
              marginTop: "30px",
            }}
            href="/createAccount"
          >
            {" "}
            Create an account{" "}
          </Link>
        </CardContent>
      </Card>
    </div>
  );
};

export default BrowserLogin;
