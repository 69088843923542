import { Button  } from "@mui/material";
import React from "react";

interface GroupPanelProps {
  currentGroup: string;
  setCurrentGroup: React.Dispatch<React.SetStateAction<string>>;
  isDesktop?: boolean;
}

const GroupPanel: React.FC<GroupPanelProps> = ({
  currentGroup,
  setCurrentGroup,
  isDesktop
}) => {
  return (
    <div style={{ padding: "20px 20px 0 20px", display: 'flex', justifyContent: 'center', gap: '14px', width: isDesktop ? "82%" :'100%'}}>
      {GROUPS.map((group, index) => (
        <GroupButton
          label={group}
          onClick={() => setCurrentGroup(group)}
          isActive={currentGroup === group}
          key={`groupButton- ${index}`}
        />
      ))}
    </div>
  );
};

export default GroupPanel;

export const GROUPS = ["A", "B", "C", "D", "E", "F"];

interface GroupButtonProps {
  label: string;
  onClick: React.Dispatch<React.SetStateAction<string>>;
  isActive: boolean;
}
const GroupButton: React.FC<GroupButtonProps> = ({
  label,
  onClick,
  isActive,
}) => {
  return (
    <Button
      style={
        isActive
          ? {
              background: "#F9BF4B",
              color: "#061237",
              textTransform: "none",
              fontWeight: "600",
              minWidth: "0",
              width: '12%',
            }
          : {
              minWidth: "0",
              width: '12%',
              background: "#454758",
              color: "#FFF",
              textTransform: "none",
              fontWeight: "600",

            }
      }
      onClick={() => onClick(label)}
    >
      {label}
    </Button>
  );
};
