import React from "react";
import FixtureTile from "../components/FixtureTile/FixtureTile";
import { Match } from "../../types/Match";
import { Bet } from "../../types/Bet";

interface FixtureBodyProps {
  currentFixtures: Match[];
  bets: Bet[];
  setCurrentModalMatch: React.Dispatch<React.SetStateAction<Match>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDesktop?: boolean;
}

const FixtureBody: React.FC<FixtureBodyProps> = ({
  currentFixtures,
  bets,
  setCurrentModalMatch,
  setIsOpen,
  isDesktop
}) => {
  return (
    <div style={{ width: "100%" }}>
      {currentFixtures.map((match) => (
        <div
          key={`${match.associationId} - fixture`}
          style={{ margin: "30px 0px", padding: "0 10px" }}
        >
          <FixtureTile
            match={match}
            bet={bets.filter(bet => bet.matchId === match.id)}
            setCurrentModalMatch={setCurrentModalMatch}
            setIsOpen={setIsOpen}
            isDesktop={isDesktop}
          />
        </div>
      ))}
    </div>
  );
};

export default FixtureBody;