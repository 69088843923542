import { Route, Routes } from "react-router-dom";
import { MobileBets } from "../MobileBets/MobileBets";
import { MobileFixtures } from "../MobileFixtures/MobileFixtures";
import { MobileRanking } from "../MobileRanking/MobileRanking";
import { MobileProfile } from "../MobileProfile/MobileProfile";
import { MobileLeagues } from "../MobileLeagues/MobileLeagues";
import { MobileFAQ } from "../MobileFAQ";
import { MobileSignIn } from "../MobileSign/MobileSignIn";
import { MobileWizard } from "../MobileCreationWizard/MobileWizard";
import CreateLeaguePanel from "../MobileCreationWizard/CreateLeaguePanel";

interface MobileBodyProps {}

export const MobileBody: React.FC<MobileBodyProps> = () => {
  const isUserSigned = window.localStorage.getItem("isSigned") === "true"; // userStatus.isUserSigned; // #TODO get info from storage
  return (
    <Routes>
      {/* in future give direction to homepage */}
      <Route
        path="/"
        element={isUserSigned ? <MobileProfile /> : <MobileSignIn />}
      />
      <Route path="/login" element={<MobileSignIn />} />
      <Route
        path="/bets"
        element={isUserSigned ? <MobileBets /> : <MobileSignIn />}
      />
      <Route path="/createAccount" element={<MobileWizard />} />
      {/* Handle when user signed in createAccount */}
      <Route
        path="/fixtures"
        element={isUserSigned ? <MobileFixtures /> : <MobileSignIn />}
      />
      <Route
        path="/ranking"
        element={isUserSigned ? <MobileRanking /> : <MobileSignIn />}
      />
      <Route
        path="/profile"
        element={isUserSigned ? <MobileProfile /> : <MobileSignIn />}
      />
      <Route
        path="/leagues"
        element={
          isUserSigned ? <MobileLeagues isUserSigned /> : <MobileSignIn />
        }
      />
      <Route path="/faq" element={<MobileFAQ />} />
      <Route
        path="/createLeague"
        element={isUserSigned ? <CreateLeaguePanel /> : <MobileSignIn />}
      />
    </Routes>
  );
};

export type RoutePath =
  | "/"
  | "/bets"
  | "/fixtures"
  | "/ranking"
  | "/profile"
  | "/leagues"
  | "/faq";
