import React from "react";
import { CircleFlag } from "react-circle-flags";
import {
  getDateFromDate,
  getLocalTimeFromDate,
} from "../../../utils/timeUtils";
import countryDict from "../../../utils/countryDict";
import { handleName } from "../../../utils/handleName";
import { Typography } from "@mui/material";
import { Match } from "../../../types/Match";

interface FixtureTileBodyProps {
  isBet: boolean;
  showDate?: boolean;
  homeTeamName: string;
  awayTeamName: string;
  date: string;
  mBot?: string;
  isPlayed: boolean;
  isDesktop?: boolean;
  match: Match;
}

const FixtureTileBody: React.FC<FixtureTileBodyProps> = ({
  isBet,
  homeTeamName,
  awayTeamName,
  date,
  showDate,
  mBot,
  isPlayed,
  isDesktop,
  match
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        minHeight: "95px",
        color: "#FDFDFD",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        marginBottom: isBet ? (mBot ? mBot : "20px") : undefined,
      }}
    >
      <Typography
        style={{
          fontWeight: "600",
          width: isDesktop ? "30%" : "20%",
          textAlign: "right",
          fontSize: isDesktop ? "28px" : undefined,
        }}
      >
        {isDesktop ? homeTeamName : handleName(homeTeamName)}
      </Typography>
      <CircleFlag
        countryCode={countryDict.get(homeTeamName) || "Poland"}
        height={isDesktop ? "60" : "30"}
        style={{ width: isDesktop ? "5%" : "10%" }}
      />
      <div
        style={{
          border: "1px solid #A2AEBB",
          borderRadius: "12.5%",
          padding: "10px 20px",
          // fontWeight: !showDate ? 700 : undefined,
          minWidth: "35px",
        }}
      >
        {" "}
        {isPlayed ? (
          <Typography style={{ textAlign: "center", fontWeight: 700  }}> {match.homeScoreRegular} - {match.awayScoreRegular} </Typography>
        ) : (
          <Typography style={{ textAlign: "center", fontSize: "14px", fontWeight:  700  }}>
            {showDate ? getDateFromDate(date) : getLocalTimeFromDate(date)}
          </Typography>
        )}
      </div>
      <CircleFlag
        countryCode={countryDict.get(awayTeamName) || "Poland"}
        height={isDesktop ? "60" : "30"}
        style={{ width: isDesktop ? "5%" : "10%" }}
      />{" "}
      <Typography
        style={{
          fontWeight: "600",
          width: isDesktop ? "30%" : "20%",
          textAlign: "left",
          fontSize: isDesktop ? "28px" : undefined,
        }}
      >
        {isDesktop ? awayTeamName : handleName(awayTeamName)}
      </Typography>
    </div>
  );
};

export default FixtureTileBody;
