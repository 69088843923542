import { Box, Button, Modal } from "@mui/material";
import React from "react";

interface DiscardChangesModalProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  confirmAction: () => void;
}

const DiscardChangesModal: React.FC<DiscardChangesModalProps> = ({
  openModal,
  setOpenModal,
  confirmAction,
}) => {
  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box sx={{ ...styleModal, width: "75%" }}>
        <h1 id="parent-modal-title"> Do you want leave? </h1>
        <p id="parent-modal-description">
          If you leave, you will lost all data put in creation panel.
        </p>
        <div style={{ display: "flex", gap: "15px" }}>
          <Button
            style={{
              color: "#FAFAFA",
              border: "1px solid #FAFAFA",
              width: "85px",
            }}
            onClick={() => setOpenModal(false)}
          >
            Cancel
          </Button>
          <Button
            style={{ border: "1px solid red", width: "85px" }}
            color="error"
            onClick={confirmAction}
          >
            Leave
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default DiscardChangesModal;

const styleModal = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#454758",
  border: "1px solid #FFF",
  borderRadius: "55px",
  color: "#FAFAFA",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
