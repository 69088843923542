import "./App.css";
import { isMobile } from "react-device-detect";
import { MobileApp } from "./Mobile/MobileApp";
import { BrowserApp } from "./Browser/BrowserApp";
import { BrowserRouter } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { UserProvider } from "./common/contexts/userContext";
import { MatchProvider } from "./common/contexts/fixturesContext";
import { BetProvider } from "./common/contexts/betContext";
import { AllUsersProvider } from "./common/contexts/allUsersContext";

function App() {
  const token = window.localStorage.getItem("accessToken");
  if (token && token !== "") {
    const decoded_token = jwtDecode(token);
    if (
      decoded_token.exp &&
      new Date(decoded_token.exp * 1000).getTime() > new Date().getTime()
    ) {
      window.localStorage.setItem("isSigned", "true");
    } else {
      window.localStorage.setItem("accessToken", "");
      window.localStorage.setItem("isSigned", "false");
    }
  }
  else{
    window.localStorage.setItem("isSigned", "false");
  }
  return isMobile ? (
    <BrowserRouter>
      <UserProvider>
        <MatchProvider>
          <BetProvider>
            <AllUsersProvider>
              <MobileApp />
            </AllUsersProvider>
          </BetProvider>
        </MatchProvider>
      </UserProvider>
    </BrowserRouter>
  ) : (
    <BrowserRouter>
      <UserProvider>
        <MatchProvider>
          <BetProvider>
            <AllUsersProvider>
              <BrowserApp />
            </AllUsersProvider>
          </BetProvider>
        </MatchProvider>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
