import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { User } from "../../types/User";
import { API_URL } from "../../utils/api";

interface AllUsersContextProps {
  users: User[];
  setUsers: React.Dispatch<React.SetStateAction<User[]>>;
}

// Create the User context with default values
const AllUsersContext = createContext<AllUsersContextProps | undefined>(
  undefined
);

export const AllUsersProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [users, setUsers] = useState<User[]>([
    {
      id: "",
      username: "",
      email: "",
      avatarUrl: "",
      leagues: [],
      favoriteTeams: [],
      lastFiveBets: [],
      score: { id: 0, perfectBets: 0, correctBets: 0, wrongBets: 0, points: 0 },
    },
  ]);
  useEffect(() => {
    try {
      fetch(API_URL + `/api/User/all`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
        },
      })
        .then(async (data) =>
          data.ok ? setUsers(await data.json()) : undefined
        )
        .catch((error) => console.log(error));
    } catch (error) {
      console.log("probably you need to re-login or you are not logged");
    }
  }, []);
  return (
    <AllUsersContext.Provider value={{ users, setUsers }}>
      {children}
    </AllUsersContext.Provider>
  );
};

export const useAllUsers = (): AllUsersContextProps => {
  const context = useContext(AllUsersContext);
  if (!context) {
    throw new Error("useMatch must be used within a MatchProvider");
  }
  return context;
};
