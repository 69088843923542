const countryArray = [
  {
    countryName: "Netherlands",
    countryCode: "nl",
  },
  {
    countryName: "Poland",
    countryCode: "pl",
  },
  {
    countryName: "England",
    countryCode: "gb-eng",
  },
  {
    countryName: "France",
    countryCode: "fr",
  },
  {
    countryName: "Germany",
    countryCode: "de",
  },
  {
    countryName: "Spain",
    countryCode: "es",
  },
  {
    countryName: "Croatia",
    countryCode: "hr",
  },
  {
    countryName: "Belgium",
    countryCode: "be",
  },
  {
    countryName: "Switzerland",
    countryCode: "ch",
  },
  {
    countryName: "Serbia",
    countryCode: "rs",
  },
  {
    countryName: "Portugal",
    countryCode: "pt",
  },
  {
    countryName: "Denmark",
    countryCode: "dk",
  },
  {
    countryName: "Scotland",
    countryCode: "gb-sct",
  },
  {
    countryName: "Türki̇ye",
    countryCode: "tr",
  },
  {
    countryName: "Austria",
    countryCode: "at",
  },
  {
    countryName: "Hungary",
    countryCode: "hu",
  },
  {
    countryName: "Slovakia",
    countryCode: "sk",
  },
  {
    countryName: "Albania",
    countryCode: "al",
  },
  {
    countryName: "Romania",
    countryCode: "ro",
  },
  {
    countryName: "Czechia",
    countryCode: "cz",
  },
  {
    countryName: "Italy",
    countryCode: "it",
  },
  {
    countryName: "Slovenia",
    countryCode: "si",
  },
  {
    countryName: "Georgia",
    countryCode: "ge",
  },
  {
    countryName: "Ukraine",
    countryCode: "ua",
  },
];

export default countryArray.sort((a, b) =>
  a.countryName < b.countryName ? -1 : 1
);
