import React from "react";
import { CircleFlag } from "react-circle-flags";

interface TeamTileProps {
  name: string;
  height?: string;
  width?: string;
  countryCode: string;
}

const TeamTile: React.FC<TeamTileProps> = ({
  name,
  height,
  width,
  countryCode,
}) => {
  return (
    <div
      style={{
        height: height || "105px",
        width: width || "105px",
        background: "#FAFAFA",
        borderRadius: "14px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        margin: "20px 0",
      }}
    >
      <CircleFlag countryCode={countryCode} height={55} width={55} />
      <span style={{ color: "black", marginTop: "10px", fontWeight: "600" }}>
        {name}
      </span>
    </div>
  );
};

export default TeamTile;
