import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import "./MobileFooterNavbar.scss";
import { Link } from "react-router-dom";
import { useState } from "react";
import {
  AccountCircle,
  FormatBold,
  People,
  SportsSoccer,
  Star,
} from "@mui/icons-material";

export interface MobileFooterNavbarProps {}

export const MobileFooterNavbar: React.FC<MobileFooterNavbarProps> = () => {
  const [currentValue, setCurrentValue] = useState("bets");
  return (
    <div className="MobileFooterNavbarLayout">
      <BottomNavigation
        style={{ width: "100vw", height: "8vh", borderRadius: "5px" }}
        value={currentValue}
        onChange={(event, newValue) => {
          setCurrentValue(newValue);
        }}
      >
        {navigationProps.map((navProp) => (
          <Link
            key={`${navProp.name} route`}
            to={navProp.routePath}
            style={{
              width: "19.7vw",
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
            }}
          >
            <BottomNavigationAction
              label={navProp.name}
              icon={navProp.icon}
              showLabel
              style={{fontWeight: '600', color: window.location.pathname.includes(navProp.routePath) ? "#F9BF4B" : undefined }}
            />
          </Link>
        ))}
      </BottomNavigation>
    </div>
  );
};

const navigationProps = [
  {
    name: "Bets",
    routePath: "/bets",
    icon: <FormatBold />,
  },
  {
    name: "Fixtures",
    routePath: "/fixtures",
    icon: <SportsSoccer />,
  },
  {
    name: "Ranking",
    routePath: "/ranking",
    icon: <Star />,
  },
  {
    name: "Leagues",
    routePath: "/leagues",
    icon: <People />,
  },
  {
    name: "Profile",
    routePath: "/profile",
    icon: <AccountCircle />,
  },
];
