import { useState } from "react";
import { Bet } from "../../types/Bet";
import BetModal from "../../Mobile/components/BetModal";
import BetCard from "../../Mobile/MobileBets/BetCard";
import { mockBet } from "../../Mobile/MobileBets/MobileBetsBody";
import { Typography } from "@mui/material";
import "./BrowserBets.scss";

interface BrowserBetsBodyProps {
  bets: Bet[];
}

const BrowserBetsBody: React.FC<BrowserBetsBodyProps> = ({ bets }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentBet, setCurrentBet] = useState<Bet>(mockBet);
  
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <BetModal
        isOpen={modalOpen}
        bet={currentBet as Bet}
        setIsOpen={setModalOpen}
        isDesktop
      />
      <div className="browser-bets">
        {bets.length > 0 &&
          bets.sort((a,b) => b.betDate > a.betDate ? 1 : -1 ).map((bet, index) => (
            <BetCard
              bet={bet}
              key={`bet-${index}`}
              setIsOpen={setModalOpen}
              setCurrentBet={setCurrentBet}
            />
          ))}
      </div>
      {bets.length === 0 && (
        <Typography
          variant="h4"
          style={{
            textAlign: "center",
            width: "100%",
            color: "#FAFAFA",
            paddingTop: "40px",
          }}
        >
          No valid bets to display{" "}
        </Typography>
      )}
    </div>
  );
};

export default BrowserBetsBody;
