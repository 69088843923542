import React, { createContext, useContext, useState, ReactNode } from "react";
import { Bet } from "../../types/Bet";

interface BetContextProps {
  bets: Bet[];
  setBets: React.Dispatch<React.SetStateAction<Bet[]>>;
}

// Create the User context with default values
const BetContext = createContext<BetContextProps | undefined>(undefined);

export const BetProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [bets, setBets] = useState<Bet[]>([]);

  return (
    <BetContext.Provider value={{ bets, setBets }}>
      {children}
    </BetContext.Provider>
  );
};

export const useBets = (): BetContextProps => {
  const context = useContext(BetContext);
  if (!context) {
    throw new Error('useMatch must be used within a MatchProvider');
  }
  return context;
};