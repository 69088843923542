import React, { createContext, useContext, useState, ReactNode } from "react";
import { User } from "../../types/User";

interface UserContextProps {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
}

// Create the User context with default values
const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<User>({
    id: "",
    username: "",
    email: "",
    avatarUrl: "",
    leagues: [],
    favoriteTeams: [],
    lastFiveBets: [],
    score: { id: 0, perfectBets: 0, correctBets: 0, wrongBets: 0, points: 0 },
  });

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextProps => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useMatch must be used within a MatchProvider");
  }
  return context;
};
