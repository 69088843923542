import { Alert } from "@mui/material";
import React from "react";

interface ErrorComponentProps {
  error: any;
  resetErrorBoundary: any;
}

const ErrorComponent: React.FC<ErrorComponentProps> = ({
  error,
  resetErrorBoundary,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Alert variant="outlined" severity="error" style={{width: "90%", background: "white"}}>
        An error occured. Our system may not work or you made unexpected action.
      </Alert>
    </div>
  );
};

export default ErrorComponent;
