import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  TextField,
  Link,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useUser } from "../../common/contexts/userContext";
import { API_URL } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { useFixtures } from "../../common/contexts/fixturesContext";
import { useBets } from "../../common/contexts/betContext";
import { Match } from "../../types/Match";
import debounce from "debounce";

export const initialLoginValues: { username: string; password: string } = {
  username: "",
  password: "",
};

export const MobileLoginPanel = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loginValues, setLoginValues] = useState(initialLoginValues);
  const { setUser } = useUser();
  const { setMatches } = useFixtures();
  const { setBets } = useBets();
  const navigate = useNavigate();
  const handleClickShowPassword = () =>
    setShowPassword((show: boolean) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  function handleChangeEmail(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setLoginValues((prev) => ({ ...prev, username: e.target.value }));
  }

  function handleChangePassword(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setLoginValues((prev) => ({ ...prev, password: e.target.value }));
  }

  async function handleLogin() {
    // Auth
    async function correctLogin(data: any) {
      // setlocal storage with token
      if(!data.isSucceed){
        alert("Incorrect credentials")
        return 
      }
      
      // user context
      window.localStorage.setItem("accessToken", data.data.accessToken);
      window.localStorage.setItem("isSigned", "true");
      setUser(data.data.user);

      // Fixtures context
      const requestOptionFixtures = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.data.accessToken}`,
          // "Cache-Control": "no-store"
        },
      };
      const responseFixtures = await fetch(
        API_URL + `/api/Match/all`,
        requestOptionFixtures
      );
      const dataFixtures = await responseFixtures.json();
      setMatches(
        dataFixtures.filter((match: Match) => match.homeTeam && match.awayTeam)
      );
      // Bet Context
      const requestOptionBets = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.data.accessToken}`,
        },
      };
      const responseBets = await fetch(
        API_URL + `/api/Bet/All/User/${data.data.user.id}`,
        requestOptionBets
      );
      const dataBets = await responseBets.json();
      setBets(dataBets);
      navigate("/profile");
    }

    const loginRequestOption = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: loginValues.username,
        password: loginValues.password,
      }),
    };

    await fetch(API_URL + "/api/Authorization/Login", loginRequestOption).then(
      async (data) =>
        data.ok 
          ? correctLogin(await data.json())
          : alert("Wrong credentials.")
    );
  }

  return (
    <Box display="flex" flexDirection="column" gap="15px">
      <div
        style={{
          fontSize: "2.75vh",
          fontWeight: "600",
          margin: "20px 0px",
        }}
      >
        {" "}
        Log In
      </div>
      <Typography> Username </Typography>

      <TextField
        variant="outlined"
        InputProps={{ className: "customedInput borderRadiusInput" }}
        type="text"
        value={loginValues.username}
        onChange={handleChangeEmail}
        name="email"
      />
      <Typography> Password </Typography>
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? "text" : "password"}
        onChange={handleChangePassword}
        value={loginValues.password}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        className="customedInput outlinedCustomInput"
        name="password"
      />
      {/* <Link
        style={{
          textAlign: "end",
          margin: 0,
          color: "#00B0FF",
          textDecoration: "none",
        }}
      >
        Forgot password?
      </Link> */}
      <Button
        style={{
          borderRadius: "25px",
          width: "100%",
          height: "45px",
          padding: 0,
          color: "#111111",
          background: "#F9BF4B",
          border: "1px black solid",
          marginTop: '30px'
        }}
        onClick={debounce(handleLogin, 300)}
      >
        Log In
      </Button>
    </Box>
  );
};
