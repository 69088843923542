import React from "react";
import { CircleFlag } from "react-circle-flags";
import { TeamInfo } from "../MobileCreationWizard/steps/PreferencesStep";
import { Typography } from "@mui/material";

interface TeamTileSmallProps {
  name: string;
  countryCode: string;
  onTeamClick: (chosenTeam: TeamInfo) => void;
}

const TeamTileSmall: React.FC<TeamTileSmallProps> = ({
  countryCode,
  name,
  onTeamClick,
}) => {
  return (
    <div
      style={{
        height: "125px",
        width: "75px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          height: "75px",
          width: "75px",
          background: "#454758",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center", 
        }}
        className="onHover-teamtile"
        onClick={() => onTeamClick({name: name, countryCode: countryCode})}
      >
        <CircleFlag countryCode={countryCode} height={45} width={45} style={{border: "transparent"}}/>
      </div>
      <Typography style={{marginTop: '5px', border: "transparent"}}> {name}</Typography>
    </div>
  );
};

export default TeamTileSmall;
