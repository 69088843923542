import { Alert, AlertColor, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";

interface AlertSnackbarProps {
  severity: AlertColor;
  message: string;
}

const AlertSnackbar: React.FC<AlertSnackbarProps> = ({ message, severity }) => {
  const [open, setOpen] = useState(false);

  const showAlert = () => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2200); 
  };

  useEffect(() => {
    showAlert();
  }, []);
  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() => setOpen(false)}
        autoHideDuration={2200}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity={severity}
          color={severity}
          style={{
            width: "80%",
            bottom: "80px",
            position: "fixed",
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AlertSnackbar;
