import { Avatar, Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import RemoveUsersPanel, { getUserLeagueById } from "./RemoveUsersPanel";
import AlertSnackbar from "../../common/AlertSnackbar";
import { API_URL } from "../../utils/api";
import { RequestLeague } from "../../types/RequestLeague";
import { useAllUsers } from "../../common/contexts/allUsersContext";
import { League } from "../../types/League";
import { Modal } from "@material-ui/core";
import { useUser } from "../../common/contexts/userContext";
import { LeagueViewTab } from "./MobileLeagues";
import debounce from "debounce";

interface ManageLeaguesPanelProps {
  manageLeagueId: number;
  isDesktop?: boolean;
  setCurrentView: React.Dispatch<React.SetStateAction<LeagueViewTab>>;
}

export async function getLeagueRequests(leagueId: number) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  };
  const data = await fetch(
    API_URL + `/api/League/Request/All/League/${leagueId}`,
    requestOptions
  );
  const dataJson = await data.json();
  return dataJson;
}

const ManageLeaguesPanel: React.FC<ManageLeaguesPanelProps> = ({
  manageLeagueId,
  isDesktop,
  setCurrentView
}) => {
  const [userRequests, setUserRequests] = useState<RequestLeague[]>([]);
  const [refetchData, setRefetchData] = useState<boolean>(false);
  const [currentLeague, setCurrentLeague] = useState<League | undefined>(
    undefined
  );
  const { users } = useAllUsers();
  const { setUser } = useUser();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  useEffect(() => {
    const response = getLeagueRequests(manageLeagueId);
    response.then((data) => setUserRequests(data));
  }, [manageLeagueId]);

  useEffect(() => {
    getUserLeagueById(manageLeagueId).then((data) => setCurrentLeague(data));
  }, [manageLeagueId]);

  function correctDelete() {
    setUser((prev) => ({
      ...prev,
      leagues: prev.leagues.filter((item) => item.id !== currentLeague?.id),
    }));
    setShowDeleteModal(false)
    setCurrentView("YourLeagues")
  }
  async function handleDelete() {
    const requestOption = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
      },
    };
    if (currentLeague) {
      await fetch(
        API_URL + `/api/League/${currentLeague.id}`,
        requestOption
      ).then((resp) =>
        resp.ok ? correctDelete() : alert("Something went wrong")
      );
    } else {
      setShowDeleteModal(false)
      alert("League cannot be deleted");
    }
  }
  return (
    <div
      style={{
        height: "100%",
        padding: isDesktop ? undefined : "30px",
        width: isDesktop ? "63%" : undefined,
      }}
    >
      <Typography
        variant="h4"
        style={{ color: isDesktop ? "#FAFAFA" : undefined }}
      >
        {" "}
        {currentLeague?.name}{" "}
      </Typography>
      <Typography
        style={{
          textAlign: "center",
          marginTop: "30px",
          marginBottom: "10px",
          letterSpacing: "1.5px",
          fontSize: "22px",
          color: isDesktop ? "#FAFAFA" : undefined,
        }}
      >
        Pending requests{" "}
      </Typography>
      <div
        style={{
          maxHeight: "35vh",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          marginBottom: "20px",
        }}
      >
        {" "}
        {userRequests.map((req) => {
          const currUser = users.filter((item) => item.id === req.userId)[0];
          return (
            currUser && (
              <UserTile
              key={`${currUser.id} tile in req`}
                name={currUser.username} // #TODO potential bug
                avatarUrl={currUser.avatarUrl}
                userId={req.userId}
                manageLeagueId={manageLeagueId}
                setUserRequests={setUserRequests}
                setRefetchData={setRefetchData}
              />
            )
          );
        })}
        {userRequests.length === 0 && (
          <Typography
            style={{ color: "#FAFAFA", textAlign: "center", margin: "10px" }}
          >
            There's no user requests awaiting for acceptance.
          </Typography>
        )}
      </div>
      <RemoveUsersPanel
        manageLeagueID={manageLeagueId}
        refetchData={refetchData}
      />
      <Button
        variant="outlined"
        color="error"
        style={{ background: "#FAFAFA" }}
        onClick={() => setShowDeleteModal(true)}
      >
        {" "}
        Delete League
      </Button>
      <ModalDelete
        open={showDeleteModal}
        onClose={setShowDeleteModal}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default ManageLeaguesPanel;

interface UserTileProps {
  name: string;
  avatarUrl: string;
  userId: string;
  manageLeagueId: number;
  setUserRequests: React.Dispatch<React.SetStateAction<RequestLeague[]>>;
  setRefetchData: React.Dispatch<React.SetStateAction<boolean>>;
}

const UserTile: React.FC<UserTileProps> = ({
  name,
  avatarUrl,
  userId,
  manageLeagueId,
  setUserRequests,
  setRefetchData,
}) => {
  const [showAlertAdd, setShowAlertAdd] = useState(false);
  const [showAlertRemove, setShowAlertRemove] = useState(false);
  function addUserToLeague() {
    function correctAddFetch() {
      setUserRequests((prev) => prev.filter((item) => item.userId !== userId));
      setShowAlertAdd(true);
      setRefetchData((prev) => !prev);
    }
    const token = window.localStorage.getItem("accessToken");
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        leagueId: manageLeagueId,
        userId: userId,
      }),
    };
    fetch(API_URL + `/api/League/Request/Accept`, requestOption).then(
      (response) =>
        response.ok
          ? correctAddFetch()
          : alert("Something went wrong - try again.")
    );
  }

  function removeUserRequest() {
    const token = window.localStorage.getItem("accessToken");
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        leagueId: manageLeagueId,
        userId: userId,
      }),
    };

    function correctFetch() {
      setShowAlertRemove(true);
      setUserRequests((prev) => prev.filter((item) => item.userId !== userId));
    }
    fetch(API_URL + `/api/League/Request/Reject`, requestOption).then(
      (response) =>
        response.ok ? correctFetch() : alert("Something went wrong")
    );
    // delete from requests
  }
  return (
    <div
      style={{
        width: "100%",
        height: "60px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #FAFAFA",
        background: "#FAFAFA",
        borderRadius: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
          padding: "5px 15px",
        }}
      >
        <Avatar src={avatarUrl} style={{ height: "45px", width: "45px" }} />
        <Typography style={{color: "#111"}}> {name} </Typography>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
          justifyContent: "flex-end",
          width: "100%",
          padding: "5px 15px",
        }}
      >
        <CancelOutlinedIcon
          className="hoverIcon"
          style={{ height: "35px", width: "35px", color: "#FA9991" }}
          onClick={debounce(removeUserRequest, 300)}
        />
        <CheckCircleOutlineIcon
          style={{ height: "35px", width: "35px", color: "#AAFAAA" }}
          onClick={debounce(addUserToLeague,300)}
          className="hoverIcon"
        />
      </div>
      {showAlertAdd && (
        <AlertSnackbar
          severity="success"
          message="User added successfully!"
          key={`${name}-tile-alert-add`}
        />
      )}
      {showAlertRemove && (
        <AlertSnackbar
          severity="info"
          message="User requests removed successfully!"
          key={`${name}-tile-alert-remove`}
        />
      )}
    </div>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface ModalDeleteProps {
  open: boolean;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  handleDelete: () => void;
}

export const ModalDelete: React.FC<ModalDeleteProps> = ({
  open,
  onClose,
  handleDelete,
}) => (
  <Modal
    open={open}
    onClose={() => onClose(false)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Do you really want to delete this League?
      </Typography>
      <Button
        id="modal-modal-description"
        sx={{ mr: 2, mt: 2 }}
        variant="outlined"
        color="warning"
        onClick={() => handleDelete()}
      >
        Delete
      </Button>
      <Button
        id="modal-modal-description"
        sx={{ mr: 2, mt: 2 }}
        variant="outlined"
        onClick={() => onClose(false)}
      >
        Cancel
      </Button>
    </Box>
  </Modal>
);

export const mockUserData = [
  { name: "user1", avatarUrl: "./images/test_avatar.jpg", id: 1 },
  { name: "user2", avatarUrl: "./images/test_avatar.jpg", id: 2 },
  { name: "user3", avatarUrl: "./images/test_avatar.jpg", id: 3 },
  { name: "user4", avatarUrl: "./images/test_avatar.jpg", id: 4 },
  { name: "user5", avatarUrl: "./images/test_avatar.jpg", id: 5 },
  { name: "user6", avatarUrl: "./images/test_avatar.jpg", id: 6 },
  { name: "user7", avatarUrl: "./images/test_avatar.jpg", id: 7 },
  { name: "user8", avatarUrl: "./images/test_avatar.jpg", id: 8 },
  { name: "user9", avatarUrl: "./images/test_avatar.jpg", id: 9 },
];
