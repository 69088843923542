import React from "react";
import { LeagueViewTab } from "./MobileLeagues";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Search, PersonOutlined } from "@mui/icons-material";
interface MobileLeaguePanelProps {
  currentView: LeagueViewTab;
  setCurrentView: React.Dispatch<React.SetStateAction<LeagueViewTab>>;
  paddingTop?: boolean;
}

const MobileLeaguePanel: React.FC<MobileLeaguePanelProps> = ({
  setCurrentView,
  currentView,
  paddingTop
}) => {
  function handleChange(
    event: React.MouseEvent<HTMLElement>,
    newAlignment: LeagueViewTab
  ) {
    if (newAlignment !== null) {
      setCurrentView(newAlignment);
    }
  }
  return (
    <ToggleButtonGroup
      color="primary"
      value={currentView}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        paddingTop: paddingTop ? "40px" : undefined,
      }}
    >
      <ToggleButton
        value="Search"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "45%",
          background: "#F9BF4B",
          color: "#111",
          borderRight: "2px solid #111",
          fontWeight: currentView === "Search" ? 600 : undefined,
        }}
      >
        Search
        <Search />{" "}
      </ToggleButton>
      <ToggleButton
        value="YourLeagues"
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "45%",
          background: "#F9BF4B",
          color: "#111",
          fontWeight: currentView === "YourLeagues" ? 600 : undefined,
        }}
      >
        My Leagues <PersonOutlined />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default MobileLeaguePanel;
