import { Button } from "@mui/material";
import React from "react";

interface FixturePanelProps {
  setCurrentPanel: React.Dispatch<React.SetStateAction<string>>;
  currentPanel: string;
  isDesktop?: boolean;
}

const FixturePanel: React.FC<FixturePanelProps> = ({
  setCurrentPanel,
  currentPanel,
  isDesktop,
}) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: isDesktop ? "center" : "space-evenly",
        height: isDesktop ? "70px" : "45px",
        gap: isDesktop ? "40px" : undefined,
        marginTop: isDesktop ? "40px" : undefined
      }}
    >
      <PanelButton
        label="Upcoming"
        onClick={setCurrentPanel}
        isActive={currentPanel === "Upcoming"}
        isDesktop={isDesktop}
      />
      <PanelButton
        label="Group Stage"
        onClick={setCurrentPanel}
        isActive={currentPanel === "Group Stage"}
        isDesktop={isDesktop}
      />
      <PanelButton
        label="Knockout"
        onClick={setCurrentPanel}
        isActive={currentPanel === "Knockout"}
        isDesktop={isDesktop}
      />
    </div>
  );
};

export default FixturePanel;

interface PanelButtonProps {
  label: string;
  onClick: React.Dispatch<React.SetStateAction<string>>;
  isActive: boolean;
  isDesktop?: boolean;
}
export const PanelButton: React.FC<PanelButtonProps> = ({
  label,
  onClick,
  isActive,
  isDesktop,
}) => {
  return (
    <Button
      style={
        isActive
          ? {
              background: "#40E0D0",
              color: "#061237",
              textTransform: "none",
              fontWeight: "600",
              padding: "0 20px",
              height: "40px",
              width: isDesktop ? "250px" : undefined,
            }
          : {
              background: "#454758",
              color: "#FFF",
              textTransform: "none",
              fontWeight: "600",
              padding: "7.5px 20px",
              height: "40px",
              width: isDesktop ? "250px" : undefined,
            }
      }
      onClick={() => onClick(label)}
    >
      {label}
    </Button>
  );
};
