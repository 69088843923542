import { Box, Modal } from "@mui/material";
import React, { useState } from "react";
import BetModalHeader from "./BetModalHeader";
import BetModalBody, { ModalValue, initModalValue } from "./BetModalBody";
import BetModalFooter from "./BetModalFooter";
import { Bet } from "../../types/Bet";
import { Match } from "../../types/Match";
import { useFixtures } from "../../common/contexts/fixturesContext";

interface BetModalProps {
  bet?: Bet;
  match?: Match;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDesktop?: boolean;
}

const BetModal: React.FC<BetModalProps> = ({
  isOpen,
  setIsOpen,
  bet,
  match,
  isDesktop
}) => {
  const [modalValue, setModalValue] = useState<ModalValue>(initModalValue);
  const { matches } = useFixtures();
  const currentMatch = matches.filter((match) => match.id === bet?.matchId)[0];

  return (
    <Modal
      open={isOpen}
      style={style}
      sx={{
        height: "100vh !important",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        width: '90%',
        alignItems: 'center'
      }}
    >
      <Box
        style={{
          padding: "10px 0",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: isDesktop ? 300 : 200,
          border: "1px solid #A97F4B",
          alignItems: "center",
          color: "#FAFAFA",
          background: "#172245",
          borderRadius: "15px",
          width: isDesktop ? "55%" :'90%'

        }}
        
      >
        <BetModalHeader
          awayCountryName={
            currentMatch?.awayTeam.internationalName ||
            match?.awayTeam.internationalName ||
            ""
          }
          homeCountryName={
            currentMatch?.homeTeam.internationalName ||
            match?.homeTeam.internationalName ||
            ""
          }
          isDesktop={isDesktop}
        />
        <BetModalBody
          homePrediction={bet?.homeTeamScoreBet || 0}
          awayPrediction={bet?.awayTeamScoreBet || 0}
          modalValue={modalValue}
          setModalValue={setModalValue}
          isDesktop={isDesktop}
        />
        <BetModalFooter
          setIsOpen={setIsOpen}
          modalValue={modalValue}
          bet={bet}
          match={match}
          isDesktop={isDesktop}
          setModalValue={setModalValue}
        />
      </Box>
    </Modal>
  );
};

export default BetModal;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
};
