import React from "react";
import TeamTileSmall from "../components/TeamTileSmall";
import { TeamInfo } from "./steps/PreferencesStep";
import countryArray from "../../utils/countryArray";
interface PickTeamsPanelProps {
  onTeamClick: (chosenTeam: TeamInfo) => void;
  isDesktop?: boolean;
}

const PickTeamsPanel: React.FC<PickTeamsPanelProps> = ({ onTeamClick, isDesktop }) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: isDesktop ? "repeat(8, 1fr)" : "repeat(3, 1fr)",
        justifyItems: "center",
        height: "100%",
      }}
    >
      {countryArray.map(({ countryName, countryCode }) => (
        <TeamTileSmall
          key={`${countryCode}-country-${countryName}`}
          name={countryName}
          countryCode={countryCode}
          onTeamClick={onTeamClick}
        />
      ))}
    </div>
  );
};

export default PickTeamsPanel;
