import React from "react";
import { MobileFAQ } from "../Mobile/MobileFAQ";

const BrowserFAQ: React.FC = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", width: '100%' }}>
      <MobileFAQ />
    </div>
  );
};

export default BrowserFAQ;
