import React from "react";
import { Button } from "@mui/material";
import debounce from "debounce";

interface NextButtonProps {
  label: string;
  setActiveStep?: React.Dispatch<React.SetStateAction<number>>;
  createAccount?: () => void;
  createLeague?: () => void;
  disabled?: boolean;
}

const NextButton: React.FC<NextButtonProps> = ({
  label,
  setActiveStep,
  disabled,
  createAccount,
  createLeague,
}) => {
  function handleClick() {
    // validateInput(); #TODO
    if (setActiveStep) {
      setActiveStep((prev) => prev + 1);
    } else if (createAccount) {
      createAccount();
    } else if (createLeague) {
      createLeague();
    }
  }
  return (
    <Button
      style={{
        width: "100%",
        borderRadius: "25px",
        background: "#F9BF4B",
        color: "#111",
        fontWeight: "600",
      }}
      onClick={debounce(handleClick, 300)}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};

export default NextButton;
