import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { API_URL } from "../../utils/api";

export type LeagueDescription = {
  leagueId: number;
  label: string;
};

export const getGeneralLeague = async () => {
  const requestOption = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  };
  const response = await fetch(
    API_URL + `/api/League/General`,
    requestOption
  ).then((data) => data.json());
  return response;
};

export const initLeagueDescription: LeagueDescription = {
  label: "General",
  leagueId: 2,
};

export interface LeaguesDropdownProps {
  setCurrentLeague: React.Dispatch<React.SetStateAction<LeagueDescription>>;
  styles?: any;
  leagueOptions: { label: string; leagueId: number }[];
  width?: string;
  currentLeague?: string;
}

const LeaguesDropdown: React.FC<LeaguesDropdownProps> = ({
  setCurrentLeague,
  styles,
  leagueOptions,
  width,
  currentLeague,
}) => {
  return (
    <Autocomplete
      onChange={(
        event: any,
        newValue: { label: string; leagueId: number } | null
      ) =>
        setCurrentLeague({
          label: newValue?.label || "",
          leagueId: newValue?.leagueId || 0,
        })
      }
      disablePortal
      autoHighlight
      id="league-panel-search"
      options={leagueOptions}
      size="small"
      sx={{ width: width ? width : "75%", color: "#FAFAFA" }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={currentLeague}
          style={{ background: "#454758" }}
          className="inputAutocompLeague"
        />
      )}
      style={styles}
    />
  );
};

export default LeaguesDropdown;
