import React from "react";
import NextButton from "../NextButton";
import { UserInfo } from "../MobileWizard";
import { TextField, Typography } from "@mui/material";

interface NameStepProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfo>>;
  userInfo: UserInfo;
  isDesktop?: boolean;
}

const NameStep: React.FC<NameStepProps> = ({
  setActiveStep,
  userInfo,
  setUserInfo,
  isDesktop,
}) => {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      setUserInfo((prev) => ({ ...prev, name: event.target.value }));
    
  }
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const specialChars = [
      "Space",
      "Backquote",
      "Minus",
      "Equal",
      "BracketLeft",
      "BracketRight",
      "Backslash",
      "Semicolon",
      "Quote",
      "Slash",
      "Comma"
    ];

    if (specialChars.includes(event.code)) {
      event.preventDefault();
    }
  };
  return (
    <div
      className="stepBody"
      style={{ height: isDesktop ? "70vh" : undefined }}
    >
      <div style={{ width: "100%" }}>
        <Typography
          variant="h4"
          style={{ marginBottom: "30px", marginTop: "20px" }}
        >
          {" "}
          Choose your name
        </Typography>
        <Typography style={{ color: "#A2AEBB", marginBottom: "40px" }}>
          Your display name can be your real name, a nickname or anything that
          you feel comfortable with others seeing. Keep in mind that you will
          use your nickname to login into app, so it cannot contain special
          signs.
        </Typography>
        <Typography style={{ marginBottom: "10px" }}> Name </Typography>

        <TextField
          style={{ width: isDesktop ? "50%" : "100%" }}
          variant="outlined"
          InputProps={{ className: "customedInput" }}
          inputProps={{ maxLength: 25 }}
          type="text"
          onKeyDown={handleKeyDown}
          value={userInfo.name}
          onChange={handleChange}
          name="name"
          placeholder="Name"
        />
      </div>
      <NextButton label="Next" setActiveStep={setActiveStep} />
    </div>
  );
};

export default NameStep;
