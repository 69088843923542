import React, { useEffect, useState } from "react";
import { getAllLeagues } from "../../Mobile/MobileLeagues/SearchLeaguePanel";
import LeaguesDropdown, {
  LeagueDescription,
  initLeagueDescription,
} from "../../Mobile/components/LeaguesDropdown";
import { League } from "../../types/League";
import LeagueInfo from "../../Mobile/MobileLeagues/LeagueInfo";
import MobileLeaguePanel from "../../Mobile/MobileLeagues/MobileLeaguePanel";
import {
  CreateLeagueButton,
  LeagueViewTab,
} from "../../Mobile/MobileLeagues/MobileLeagues";
import YourLeaguesPanel from "../../Mobile/MobileLeagues/YourLeaguesPanel";
import ManageLeaguesPanel from "../../Mobile/MobileLeagues/ManageLeaguePanel";
import { useUser } from "../../common/contexts/userContext";

interface BrowserLeaguesProps {}

const BrowserLeagues: React.FC<BrowserLeaguesProps> = () => {
  const [currentView, setCurrentView] = useState<LeagueViewTab>("Search");
  const [manageLeagueId, setManageLeagueId] = useState(0);
  const {user} = useUser();
  const [leaguesLabels, setLeaguesLabels] = useState([initLeagueDescription]);
  const [currentLeagueDescription, setCurrentLeagueDescription] =
    useState<LeagueDescription>(initLeagueDescription);
  const [currentLeague, setCurrentLeague] = useState<League | undefined>(
    undefined
  );
  const [allLeagues, setAllLeagues] = useState<League[]>([]);
  const showButton = user.leagues.filter((league) => league.adminId === user.id).length < 3;

  useEffect(() => {
    getAllLeagues().then((data) => {
      const currLeague = data.filter(
        (item: League) => item.name === "General"
      )[0];
      setLeaguesLabels(
        data.map((item: League) => ({
          label: item.name,
          leagueId: item.id,
        }))
      );
      setCurrentLeague(currLeague);
      setAllLeagues(data);
      setCurrentLeagueDescription({
        label: currLeague.name,
        leagueId: currLeague.id,
      });
    });
  }, []);

  useEffect(() => {
    setCurrentLeague(
      allLeagues.filter(
        (league: League) => league.id === currentLeagueDescription.leagueId
      )[0]
    );
  }, [allLeagues, currentLeagueDescription]);
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          margin: "40px 0",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          gap: "30px",
        }}
      >
        <div style={{ width: "71%" }}>
          <MobileLeaguePanel
            currentView={currentView}
            setCurrentView={setCurrentView}
          />
        </div>
        {currentView === "Search" && (
          <LeaguesDropdown
            setCurrentLeague={setCurrentLeagueDescription}
            leagueOptions={leaguesLabels}
            width="64%"
            currentLeague={currentLeague?.name}
          />
        )}
      </div>
      <div style={{ width: "64%" }}>
        {currentView === "YourLeagues" && (
          <YourLeaguesPanel
            setManageLeagueId={setManageLeagueId}
            setCurrentView={setCurrentView}
            isDesktop
          />
        )}
      </div>

      {currentView === "Search" && (
        <LeagueInfo
          currentLeague={currentLeague}
          width="64%"
          buttonHeight="60px"
          truncateValue={25}
          height="400px"
          isDesktop
        />
      )}

      {currentView === "ManageLeagues" && (
        <ManageLeaguesPanel manageLeagueId={manageLeagueId} isDesktop setCurrentView={setCurrentView}/>
      )}
      {(currentView === "Search" || currentView === "YourLeagues") && showButton  && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "64%",
            paddingTop: currentView === "YourLeagues" ? "20px" : undefined,
          }}
        >
          <CreateLeagueButton />
        </div>
      )}
    </div>
  );
};

export default BrowserLeagues;
