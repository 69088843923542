import { TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import NextButton from "../../Mobile/MobileCreationWizard/NextButton";
import { API_URL } from "../../utils/api";
import { League } from "../../types/League";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../common/contexts/userContext";
import CreateLeagueUploadPhoto from "../../Mobile/MobileCreationWizard/CreateLeagueUploadPhoto";

const BrowserCreateLeague: React.FC = () => {
  const navigate = useNavigate();
  const [newLeagueName, setNewLeagueName] = useState<string>("");
  const [leagueDescription, setLeagueDescription] = useState<string>("");
  const { user, setUser } = useUser();
  const [imgBlobLink, setImgBlobLink] = useState<string>("");
  const [uploadedImage, setUploadedImage] = useState<any>();

  async function sendRequest(uploadedImage: any, id: number) {
    const formData = new FormData();
    formData.append("File", uploadedImage);
    formData.append("LeagueID", id.toString());

    const postRequestOptions = {
      method: "POST",
      body: formData,
    };
    const response = await fetch(
      API_URL + "/api/Image/LeagueImg",
      postRequestOptions
    );
    return await response.json();
  }
  function handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    setNewLeagueName(event.target.value);
  }
  function handleChangeLeague(event: React.ChangeEvent<HTMLInputElement>) {
    setLeagueDescription(event.target.value);
  }

  function handleCreateLeague() {
    const createLeagueBody = {
      name: newLeagueName,
      description: leagueDescription,
      leagueImageUrl: "",
      adminId: user.id,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify(createLeagueBody),
    };
    async function afterFetch(response: Response) {
      const data = await response.json();
      const newLeague: League = {
        id: data.id,
        name: data.name,
        description: data.description,
        userIds: data.userIds,
        leagueImageUrl: data.leagueImageUrl,
        adminId: user.id,
        topPoints: data.topPoints,
        topScorer: user.username,
        usersCount: data.userCount,
      };
      setUser((prev) => ({ ...prev, leagues: [...prev.leagues, newLeague] }));
      navigate("/leagues");
      setImgBlobLink(newLeague.leagueImageUrl);
      const resp = sendRequest(uploadedImage, data.id);
      resp.then((data) => setImgBlobLink(data.leagueImageUrl));
    }

    fetch(API_URL + "/api/League", requestOptions).then((response) =>
      response.ok
        ? afterFetch(response)
        : alert("League couldn't be created - try once more")
    );
  }

  return (
    <div
      style={{
        padding: "40px 120px",
        color: "#FAFAFA",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "90%",
      }}
    >
      <div style={{}}>
        <Typography variant="h4" style={{ width: "100%" }}>
          {" "}
          Create your league
        </Typography>
        <Typography style={{ color: "#A2AEBB", margin: "25px 0" }}>
          You can create your own league to compete with your friends or other
          users. After creating a league, you can either invite members or
          members may find your league and ask to join. <br /> Remember that
          only the founder of the league can be the admin.
        </Typography>
        <Typography style={{ marginBottom: "10px" }}> League Name </Typography>
        <TextField
          style={{ width: "50%", marginBottom: "20px" }}
          variant="outlined"
          InputProps={{ className: "customedInput" }}
          inputProps={{ maxLength: 20 }}
          type="text"
          value={newLeagueName}
          onChange={handleChangeName}
          name="name"
          placeholder="(at least 3 letters)"
        />
        <Typography style={{ margin: "10px 0" }}>
          {" "}
          League Description{" "}
        </Typography>

        <TextField
          style={{ width: "50%" }}
          InputProps={{ className: "customedInputDescription" }}
          inputProps={{ maxLength: 150 }}
          type="text"
          value={leagueDescription}
          onChange={handleChangeLeague}
          name="name"
          placeholder="(optional)"
          rows={3}
          multiline
          maxRows={3}
        />
        <div style={{ marginTop: "40px" }}>
          <CreateLeagueUploadPhoto
            buttonLabel="Upload a League Photo"
            imgBlobLink={imgBlobLink}
            setUploadedImage={setUploadedImage}
            uploadedImage={uploadedImage}   
            isDesktop   
          />
        </div>
      </div>

      <NextButton
        label="Create League"
        createLeague={handleCreateLeague}
        disabled={newLeagueName.length < 2}
      />
    </div>
  );
};

export default BrowserCreateLeague;
