import React, { useEffect, useState } from "react";
import NextButton from "../NextButton";
import { UserInfo } from "../MobileWizard";
import TeamTile from "../../components/TeamTile";
import PickTeamsPanel from "../PickTeamsPanel";
import _isEqual from "lodash/isEqual";
import { Typography } from "@mui/material";
import debounce from "debounce";

interface PreferencesStepProps {
  createAccount: () => void;
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfo>>;
  userInfo: UserInfo;
  isDesktop?: boolean;
}

const PreferencesStep: React.FC<PreferencesStepProps> = ({
  createAccount,
  setUserInfo,
  userInfo,
  isDesktop
}) => {
  const [chosenTeams, setChosenTeams] = useState<TeamInfo[]>(
    userInfo.preferences
  );

  function onTeamClick(chosenTeam: TeamInfo) {
    const lenBefore = chosenTeams.length;
    const currentArray = chosenTeams.filter(
      (item) => !_isEqual(item, chosenTeam)
    );
    if (lenBefore !== currentArray.length) {
      setChosenTeams(currentArray);
    } else {
      if (chosenTeams.length < 3) {
        setChosenTeams((prev) => [...prev, chosenTeam]);
      } else {
        const currentTeams = chosenTeams.pop();
        currentTeams
          ? setChosenTeams([chosenTeam, ...chosenTeams])
          : setChosenTeams([chosenTeam]);
      }
    }
  }
  useEffect(() => {
    setUserInfo((prev) => ({ ...prev, preferences: chosenTeams }));
  }, [chosenTeams, setUserInfo]);
  return (
    <div className="stepBodyTest" style={{height: isDesktop ? '75vh' : undefined,  overflowY: 'auto'}}>
      <div style={{ height: isDesktop ? "62%": "50%" }}>
        <Typography variant="h4" style={{marginTop: '20px', marginBottom: '10px'}}>Select your favourite teams</Typography>
        <Typography style={{ color: "#A2AEBB" }}>
          Your choices will personalize your experience, bringing you closer to
          the action and updates that matter to you. (max 3)
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent:
              chosenTeams.length > 2 && !isDesktop ? "space-between" : undefined,
            gap: chosenTeams.length > 2 && !isDesktop ? undefined : "43px",
            paddingLeft: isDesktop ? "60px" : undefined
          }}
        >
          {chosenTeams.map(({ name, countryCode }, index) => (
            <TeamTile
              name={name}
              key={`team${index}`}
              countryCode={countryCode}
            />
          ))}
        </div>
        <div style={{ overflow: "auto", marginTop: "20px", height: "85%" }}>
          <PickTeamsPanel onTeamClick={onTeamClick} isDesktop={isDesktop}/>
        </div>
      </div>

      <NextButton label="Create your account" createAccount={debounce(createAccount, 300)} />
     
    </div>
  );
};

export default PreferencesStep;

export type TeamInfo = { name: string; countryCode: string };
