import { Box, Button } from "@mui/material";
import React from "react";
import { Bet } from "../../types/Bet";
import { API_URL } from "../../utils/api";
import { ModalValue } from "./BetModalBody";
import { Match } from "../../types/Match";
import { useUser } from "../../common/contexts/userContext";
import { useBets } from "../../common/contexts/betContext";
import debounce from "debounce";

interface BetModalFooterProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  modalValue: ModalValue;
  bet?: Bet;
  match?: Match;
  isDesktop?: boolean;
  setModalValue: React.Dispatch<React.SetStateAction<ModalValue>>;
}

function sendBetRequestFromMatch(
  match: Match,
  modalValue: ModalValue,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  userId: string,
  setBets: React.Dispatch<React.SetStateAction<Bet[]>>,
  betCurr: Bet[]
) {
  const newBet: Bet = {
    ...betCurr[0],
    homeTeamScoreBet: Number(modalValue.homeScore),
    awayTeamScoreBet: Number(modalValue.awayScore),
  };
  async function correctFetchMatch(body?: any) {
    if (body) {
      const newBet = await body.json();
      setBets((prev) => [...prev, newBet]);
    } else {
      setBets((prev) => [
        ...prev.filter((item) => item.id !== betCurr[0].id),
        newBet,
      ]);
    }

    setIsOpen(false);
  }

  if (betCurr.length > 0) {
    const requestOption = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify({
        id: newBet.id,
        homeTeamScoreBet: newBet.homeTeamScoreBet,
        awayTeamScoreBet: newBet.awayTeamScoreBet,
        matchID: newBet.matchId,
        bettorId: newBet.bettorId, // consider giving id of user from localstorage/context
      }),
    };

    fetch(API_URL + `/api/Bet/${newBet.id}`, requestOption).then((response) =>
      response.ok === true
        ? correctFetchMatch() // after fetch, update of component is done
        : alert("Something went wrong - try bet a match once again")
    );
  } else {
    const requestOption = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify({
        homeTeamScoreBet: modalValue.homeScore,
        awayTeamScoreBet: modalValue.awayScore,
        matchId: match.id,
        bettorId: userId, // consider giving id of user from localstorage/context
      }),
    };

    fetch(API_URL + `/api/Bet`, requestOption).then((response) =>
      response.ok === true
        ? correctFetchMatch(response) // after fetch, update of component is done
        : alert("Something went wrong - try bet a match once again")
    );
  }
}

function sendBetUpdateRequest(
  bet: Bet,
  modalValue: ModalValue,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setBets: React.Dispatch<React.SetStateAction<Bet[]>>
) {
  const newBet: Bet = {
    ...bet,
    homeTeamScoreBet: Number(modalValue.homeScore),
    awayTeamScoreBet: Number(modalValue.awayScore),
  };
  const requestOption = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
    body: JSON.stringify({
      id: newBet.id,
      homeTeamScoreBet: newBet.homeTeamScoreBet,
      awayTeamScoreBet: newBet.awayTeamScoreBet,
      matchID: newBet.matchId,
      bettorId: newBet.bettorId, // consider giving id of user from localstorage/context
    }),
  };
  function correctFetch() {
    setBets((prev) => [newBet, ...prev.filter((item) => item.id !== bet.id) ]);
    setIsOpen(false);
  }
  fetch(API_URL + `/api/Bet/${bet.id}`, requestOption).then((response) =>
    response.ok === true
      ? correctFetch()
      : alert("Something went wrong - try bet a match once again")
  );
}
const BetModalFooter: React.FC<BetModalFooterProps> = ({
  setIsOpen,
  modalValue,
  bet,
  match,
  isDesktop,
  setModalValue,
}) => {
  const { user } = useUser();
  const { bets, setBets } = useBets();

  function handleBet() {
    if (modalValue.awayScore !== null && modalValue.homeScore !== null) {
      if (bet) sendBetUpdateRequest(bet, modalValue, setIsOpen, setBets);
      if (match) {
        const betCurr = bets.filter((bet) => bet.matchId === match.id);

        sendBetRequestFromMatch(
          match,
          modalValue,
          setIsOpen,
          user.id,
          setBets,
          betCurr
        );
      }
      setModalValue({ awayScore: null, homeScore: null });

      // problem
    } else {
      alert("At least on of the inputs is empty");
    }
  }
  return (
    <Box
      style={{
        width: "90%",
        textAlign: "center",
        display: "flex",
        justifyContent: "flex-end",
        gap: "20px",
        paddingBottom: "10px",
      }}
    >
      <Button
        onClick={() => {
          setModalValue({ awayScore: null, homeScore: null });
          setIsOpen(false);
        }}
        style={{
          background: "#454758",
          width: "50%",
          color: "#C2BECB",
          fontWeight: 600,
        }}
      >
        Cancel
      </Button>
      <Button
        style={{
          height: isDesktop ? "50px" : "35px",
          width: "50%",
          background:
            modalValue.awayScore === null || modalValue.homeScore === null
              ? "#F9BF4B"
              : "#D99F2B",
          color: "#111",
          fontWeight: 600,
        }}
        size="large"
        onClick={debounce(handleBet, 300)}
        disabled={
          modalValue.awayScore === null || modalValue.homeScore === null
        }
      >
        Bet
      </Button>
    </Box>
  );
};

export default BetModalFooter;
