import React, { useState } from "react";
import BetCard from "./BetCard";
import BetModal from "../components/BetModal";
import { Bet } from "../../types/Bet";
import { Match } from "../../types/Match";

interface MobileBetsBodyProps {
  bets: Bet[];
}

const MobileBetsBody: React.FC<MobileBetsBodyProps> = ({ bets }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentBet, setCurrentBet] = useState<Bet>(mockBet);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        overflow: "auto",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <BetModal
        isOpen={modalOpen}
        bet={currentBet as Bet}
        setIsOpen={setModalOpen}
      />
      <div style={{ height: "100%" }}>
        {bets.length === 0 && <div>No valid bets to display! </div>}

        {bets.length > 0 &&
          bets.sort((a,b) => b.betDate > a.betDate ? 1 : -1 ).map((bet, index) => (
            <BetCard
              bet={bet}
              key={`bet-${index}`}
              setIsOpen={setModalOpen}
              setCurrentBet={setCurrentBet}
            />
          ))}
      </div>
    </div>
  );
};

export default MobileBetsBody;

export const mockBet: Bet = {
  id: 0,
  homeTeamScoreBet: 0,
  awayTeamScoreBet: 0,
  points: 1,
  isActive: true,
  matchId: 0,
  // bettor: { id: "", username: "none", email: "none", leagues: [], avatarUrl: "", score: {id: 0, perfectBets: 0, correctBets: 0, wrongBets: 0, points: 0} },
  bettorId: 0,
  betDate: "none",
  betResult: 0,
};

export const mockMatch: Match = {
  wasPlayed: false,
  id: 0,
  awayTeam: {
    id: 0,
    associationId: "200117",
    countryCode: "SCO",
    internationalName: "Scotland",
    organizationId: "200117",
    teamCode: "SCO",
    typeTeam: "NATIONAL",
  },
  matchType: { type: "Group", value: "A" },
  awayTeamId: 14,
  homeTeam: {
    id: 0,
    associationId: "200047",
    countryCode: "GER",
    internationalName: "Germany",
    organizationId: "200047",
    teamCode: "GER",
    typeTeam: "NATIONAL",
  },
  homeTeamId: 1,
  kickOffTime: {
    kickOffTimeId: 1,
    date: "2024-06-14",
    dateTime: "2024-06-14T19:00:00Z",
    utcOffsetInHours: 2,
  },
  matchday: {
    matchdayId: 1,
    competitionId: "3",
    dateFrom: "2024-06-13T22:00:00+0000",
    dateTo: "2024-06-18T21:59:59+0000",
    format: "REGULAR",
    longName: "Matchday 1",
    name: "MD1",
    roundId: "2001079",
    seasonYear: "2024",
    sequenceNumber: "1",
    type: "MATCHDAY",
  },
  associationId: "2036161",
  homeScoreRegular: 0,
  awayScoreRegular: 0,
};
