import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { API_URL } from "../../utils/api";
import { League } from "../../types/League";
import AlertSnackbar from "../../common/AlertSnackbar";
import { useUser } from "../../common/contexts/userContext";
import { useAllUsers } from "../../common/contexts/allUsersContext";

interface RemoveUsersPanelProps {
  manageLeagueID: number;
  refetchData: boolean;
}

export async function getUserLeagueById(id: number) {
  const requestOption = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  };
  const response = await (
    await fetch(API_URL + `/api/League/${id}`, requestOption)
  ).json();
  return response;
}

const RemoveUsersPanel: React.FC<RemoveUsersPanelProps> = ({
  manageLeagueID,
  refetchData,
}) => {
  const [league, setLeague] = useState<League | undefined>();
  const { user } = useUser();
  useEffect(() => {
    getUserLeagueById(manageLeagueID).then((data) => setLeague(data));
  }, [manageLeagueID, refetchData]);
  return (
    <Accordion
      style={{
        color: "#FAFAFA",
        borderTopLeftRadius: "20px",
        marginBottom: "40px",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: "#FAFAFA" }} />}
        aria-controls="panel1-content"
        id="panel1-header"
        style={{
          background: "#454758",
          color: "#FAFAFA",
          borderTopLeftRadius: "20px",
        }}
      >
        <Typography style={{ fontSize: "18px", fontWeight: "600" }}>
          Remove Users from {league?.name} league
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ marginBottom: "10px", background: "transparent" }}>
          {league?.userIds
            ?.filter((item) => user.id !== item)
            .map((userId, index) => (
              <RemoveUserTile
                userId={userId}
                manageLeagueID={manageLeagueID}
                key={`${index}- remove - user - tile`}
              />
            ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

interface RemoveUserTileProps {
  userId: string;
  manageLeagueID: number;
}

const RemoveUserTile: React.FC<RemoveUserTileProps> = ({
  userId,
  manageLeagueID,
}) => {

  const [showAlertRemove, setShowAlertRemove] = useState(false);
  const [label, setLabel] = useState("Remove");
  const {users} = useAllUsers();
  const currentUser = users.filter((user) => user.id === userId)[0];
  function handleClick() {
    function handleDelete() {
      setShowAlertRemove(true);
      setLabel("User removed");
    }

    const requestOption = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
      },
      body: JSON.stringify({
        leagueId: manageLeagueID,
        usersId: [userId],
      }),
    };
    fetch(API_URL + `/api/League/Users/${manageLeagueID}`, requestOption).then(
      (response) =>
        response.ok
          ? handleDelete()
          : alert("Something went wrong - try again or refresh the page")
    );
  }
  return (
    <div
      style={{
        borderBottom: "1px solid black",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          color: "#111",
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        {" "}
        <Avatar src={currentUser.avatarUrl} alt="user_avatar" />{" "}
        {currentUser.username}
      </div>
      <div>
        <Button
          variant="outlined"
          color="warning"
          size="small"
          disabled={label !== "Remove"}
          onClick={handleClick}
        >
          {" "}
          {label}
        </Button>{" "}
      </div>
      {showAlertRemove && (
        <AlertSnackbar
          severity="info"
          message="User removed from league"
          key={`${currentUser.username}-tile-alert-remove`}
        />
      )}
    </div>
  );
};

export default RemoveUsersPanel;
