import React, { useState } from "react";
import NextButton from "../NextButton";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { UserInfo } from "../MobileWizard";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface PasswordStepProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfo>>;
  userInfo: UserInfo;
  isDesktop?: boolean;
}

const PasswordStep: React.FC<PasswordStepProps> = ({
  setActiveStep,
  userInfo,
  setUserInfo,
  isDesktop,
}) => {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setUserInfo((prev) => ({ ...prev, password: event.target.value }));
  }
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () =>
    setShowPassword((show: boolean) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <div
      className="stepBody"
      style={{ height: isDesktop ? "70vh" : undefined }}
    >
      <div style={{ width: "100%" }}>
        <Typography
          variant="h4"
          style={{ marginBottom: "50px", marginTop: "20px" }}
        >
          {" "}
          Create a password
        </Typography>
        <Typography style={{ color: "#A2AEBB", marginBottom: "40px" }}>
          Password must contain at least:
          <ul>
            <li>One uppercase letter(A-Z)</li>
            <li>Special sign (!#@)</li>
            <li>Number (0-9)</li>
            <li>At least 8 letters</li>
          </ul>
        </Typography>

        <Typography style={{ marginBottom: "10px" }}> Password </Typography>
        <OutlinedInput
          style={{ width: isDesktop ? "50%" : "100%" }}
          id="outlined-adornment-password"
          type={showPassword ? "text" : "password"}
          value={userInfo.password}
          onChange={handleChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          className="customedInput"
          name="password"
        />
        {!validatePassword(userInfo.password) && <Typography style={{ color: "#A2AEBB", marginTop: '5px'}}> Password doesn't meet requirements! </Typography>}
      </div>

      <NextButton
        label="Next"
        setActiveStep={setActiveStep}
        disabled={!validatePassword(userInfo.password)}
      ></NextButton>
    </div>
  );
};

export default PasswordStep;

const validatePassword = (password: string): boolean => {
  const hasUppercase = /[A-Z]/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  const hasNumber = /[0-9]/.test(password);
  const isLongEnough = password.length >= 8;

  return hasUppercase && hasSpecialChar && hasNumber && isLongEnough;
};
