import { useEffect, useState } from "react";
import MobileRankingTable from "./MobileRankingTable";
import LeaguesDropdown, {
  LeagueDescription,
  getGeneralLeague,
} from "../components/LeaguesDropdown";
import { useUser } from "../../common/contexts/userContext";
import { API_URL } from "../../utils/api";
import { RankingData } from "../../types/RankingData";
import { League } from "../../types/League";
import { useLocation } from "react-router-dom";
import { CircularProgress, Typography } from "@mui/material";
import { AccessTimeOutlined } from "@mui/icons-material";

export async function fetchLeagueInfo(leagueId: number) {
  const token = window.localStorage.getItem("accessToken");
  const requestOption = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(
    API_URL + `/api/LeagueRanking/${leagueId}`,
    requestOption
  );
  const data = await response.json();
  return data;
}

export const MobileRanking = () => {
  const { state } = useLocation();
  const { user } = useUser();
  const [lastUpdate, setLastUpdate] = useState("");
  const [initLeagueDescription, setInitLeagueDescription] =
    useState<LeagueDescription>({ leagueId: -1, label: "General" });
  const stylesLeaguesDropdown = { marginBottom: "20px" };
  const [leagueToDisplay, setLeagueToDisplay] = useState<LeagueDescription>(
    state && state.leagueOption ? state.leagueOption : initLeagueDescription
  );
  const [rankingData, setRankingData] = useState<RankingData[]>([]);

  let isLoading = rankingData.length === 0 || lastUpdate === "";

  useEffect(() => {
    if (
      initLeagueDescription.leagueId !== -1 ||
      (state && state.leagueOption)
    ) {
      const response = fetchLeagueInfo(leagueToDisplay.leagueId);
      response.then((data) => {
        setRankingData(data.rankingRows as RankingData[]);
      });
    } else {
      getGeneralLeague().then((data: any) => {
        setLeagueToDisplay({ leagueId: data.id, label: "General" });
        setInitLeagueDescription({ leagueId: data.id, label: "General" });
      });
    }
  }, [leagueToDisplay, initLeagueDescription]);

  useEffect(() => {
    getLastUpdate().then((data) => {
      setLastUpdate(data);
    });
  }, []);

  return (
    <div
      style={{
        height: "100%",
        overflowY: "auto",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "40px 0px",
      }}
    >
      <LeaguesDropdown
        setCurrentLeague={setLeagueToDisplay}
        styles={stylesLeaguesDropdown}
        leagueOptions={user.leagues.map((item: League) => ({
          label: item.name,
          leagueId: item.id,
        }))}
        currentLeague={leagueToDisplay.label}
      />
      {/* <Typography
        variant="h4"
        style={{ margin: "10px 20px", letterSpacing: "1.5px" }}
      >
        {" "}
        {leagueToDisplay.label}
      </Typography> */}
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <MobileRankingTable leagueRankingData={rankingData} />
          <Typography
            style={{
              width: "100%",
              color: "#B6B7BA",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
              gap: "5px",
            }}
          >
            <AccessTimeOutlined />
            Updated: {formatDateUpdate(new Date(lastUpdate))}
          </Typography>
        </>
      )}
    </div>
  );
};

export async function getLastUpdate() {
  const response = await fetch(API_URL + `/api/Score/LastUpdate`);
  return await response.json();
}

export function formatDateUpdate(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours() + 2).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${year}-${month}-${day} ${hours}:${minutes}`;
}
