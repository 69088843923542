import moment from "moment";
import FixtureMenu from "./FixtureMenu";
import { useEffect, useState } from "react";
import FixtureBody from "./FixtureBody";
import FixturePanel from "./FixturePanel";
import { Match } from "../../types/Match";
import { useFixtures } from "../../common/contexts/fixturesContext";
import FixtureKnockout from "./FixtureKnockout";
import FixtureGroup from "./FixtureGroup";
import { useBets } from "../../common/contexts/betContext";
import BetModal from "../components/BetModal";
import { mockMatch } from "../MobileBets/MobileBetsBody";
import { CircularProgress } from "@mui/material";

export const MobileFixtures = () => {
  const [allFixtures, setAllFixtures] = useState<Match[]>([]);
  const [currentFixtures, setCurrentFixtures] = useState<Match[]>([]);
  const [currentPanel, setCurrentPanel] = useState("Upcoming");
  const [value, setValue] = useState(4); // 4 means today
  const { matches } = useFixtures();
  const { bets } = useBets();
  // modalbet stuff
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentModalMatch, setCurrentModalMatch] = useState<Match>(mockMatch);
  function sortFixturesByDay(day: string) {
    setCurrentFixtures(
      allFixtures.filter((match) => match.kickOffTime?.date === day)
    );
  }

  useEffect(() => {
    if (allFixtures.length > 0) {
      sortFixturesByDay(
        moment(Date())
          .add(value - 4, "days")
          .format("YYYY-MM-DD")
      );
    }
  }, [value, allFixtures]);

  useEffect(() => {
    setAllFixtures(matches);
  }, [currentPanel, matches]);

  return (
    <>
      {matches.length === 0 ? (
        <CircularProgress />
      ) : (
        <div
          style={{
            height: "100%",
            width: "100%",
            paddingTop: "40px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflowY: "auto",
          }}
        >
          <FixturePanel
            setCurrentPanel={setCurrentPanel}
            currentPanel={currentPanel}
          />
          {currentPanel === "Upcoming" && (
            <>
              <FixtureMenu
                dayLabels={createDaysArray()}
                value={value}
                setValue={setValue}
              />
              <FixtureBody
                currentFixtures={currentFixtures}
                bets={bets}
                setCurrentModalMatch={setCurrentModalMatch}
                setIsOpen={setIsModalOpen}
              />
            </>
          )}
          {currentPanel === "Group Stage" && (
            <FixtureGroup
              setCurrentModalMatch={setCurrentModalMatch}
              setIsModalOpen={setIsModalOpen}
            />
          )}
          {currentPanel === "Knockout" && <FixtureKnockout />}
          <BetModal
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            match={currentModalMatch}
          />
        </div>
      )}
    </>
  );
};

export function createDaysArray() {
  let arrayOfMenuLabels = [];
  for (let i = 4; i > 1; i--) {
    arrayOfMenuLabels.push(moment(Date()).add(-i - 52, "days").format("DD MMMM"));
  }
  arrayOfMenuLabels.push("Yesterday");
  arrayOfMenuLabels.push("Today");
  arrayOfMenuLabels.push("Tomorrow");
  for (let i = 2; i < 15; i++) {
    arrayOfMenuLabels.push(moment(Date()).add(i - 52, "days").format("DD MMMM"));
  }
  return arrayOfMenuLabels;
}

export function isSameDay(date1: Date, date2: Date): boolean {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}
