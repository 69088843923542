import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const MobileFAQ = () => {
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        overflowY: "auto",
      }}
    >
      {rules.map(({ header, description }) => (
        <Accordion
          key={`${header} - accordionFAQ`}
          style={{ width: "100%", marginBottom: "10px" }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: "#FAFAFA" }} />}
            aria-controls="panel1-content"
            id="panel-faq-header"
            style={{ background: "#454758", color: "#FAFAFA" }}
          >
            <Typography> {header}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{description}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

const rules = [
  {
    header: "How to score points?",
    description: (
      <Typography>
        In order to get points, users must bet on matches. Bets must be created
        before the match starts, otherwise the user loses the chance to bet on
        the match.
      </Typography>
    ),
  },
  {
    header: "How to win league?",
    description: (
      <Typography>
        To win the league you need to gather the highest amount of points in
        specific league.
      </Typography>
    ),
  },
  {
    header: "How many points I receive for correct bet?",
    description: (
      <Typography>
        Player receive 2 points for betting a correct match outcome or 4 points
        for betting exact score. In knockout stage bets are multiplied by a
        factor. <br /> <br />
        1/8 - 3pts/6pts <br /> 1/4 - 4pts/8pts <br /> 1/2 - 5pts/10pts <br />{" "}
        Final - 6pts/12pts
      </Typography>
    ),
  },
  {
    header: "Which score should I bet in Knockout Stage?",
    description: (
      <Typography>
        In Knockout Stage we take into consideration only the result from
        standard time (90min).
      </Typography>
    ),
  },
  {
    header: "Who can manage a league?",
    description: (
      <Typography>
        The founder of a league might be the only administrator of league.,
      </Typography>
    ),
  },
  {
    header: "What if I forget my password?",
    description:
      "Contact support at gacek.filip12@gmail.com, after that your password should be reset.",
  },
  {
    header: "What if two user have equal amount of points?",
    description:
      "The place in the ranking is determined by the number of Pefect Bets. More Perfect Bets - Higher place in the ranking",
  },
];
