import React, { useEffect, useState } from "react";
import GroupTable from "./GroupTable";
import GroupPanel from "./GroupPanel";
import { useFixtures } from "../../common/contexts/fixturesContext";
import { Card } from "@mui/material";
import FixtureTileBetHeader from "../components/FixtureTile/FixtureTileBetHeader";
import FixtureTileBody from "../components/FixtureTile/FixtureTileBody";
import { GroupTableData } from "../../types/GroupTableData";
import { API_URL } from "../../utils/api";
import { isSameDay } from "./MobileFixtures";
import { useBets } from "../../common/contexts/betContext";
import { Bet } from "../../types/Bet";
import { Match } from "../../types/Match";
import "./FixtureStyles.scss";
import { TWO_HOURS } from "../components/FixtureTile/FixtureTile";

async function getTableData(group: string) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  };
  const response = await fetch(
    API_URL + `/api/Score/TeamTable/${group}`,
    requestOptions
  );
  const data = await response.json();
  return data;
}

interface FixtureGroupProps {
  setCurrentModalMatch: React.Dispatch<React.SetStateAction<Match>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDesktop?: boolean;
}

const FixtureGroup: React.FC<FixtureGroupProps> = ({
  setCurrentModalMatch,
  setIsModalOpen,
  isDesktop,
}) => {
  const [currentGroup, setCurrentGroup] = useState("A");
  const { matches } = useFixtures();
  const { bets } = useBets();
  const [currentFixtures, setCurrentFixtures] = useState(
    matches.filter(
      (match) =>
        match.matchType.type === "Group" &&
        match.matchType.value === currentGroup // filter by ongoing
    )
  );
  const [currentTableData, setCurrentTableData] = useState<GroupTableData[]>(
    []
  );
  useEffect(() => {
    setCurrentFixtures(
      matches.filter(
        (match) =>
          match.matchType.type === "Group" &&
          match.matchType.value === currentGroup &&
          !match.wasPlayed
      )
    );
    const currentGroupData = getTableData(currentGroup);
    currentGroupData.then((data) => setCurrentTableData(data));
  }, [currentGroup, matches]);

  return (
    <div
      style={{
        display: "flex",
        gap: "20px",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "10px",
        width: isDesktop ? "75%" : "89%",
        height: "100%",
      }}
    >
      <GroupPanel
        currentGroup={currentGroup}
        setCurrentGroup={setCurrentGroup}
        isDesktop={isDesktop}
      />
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <GroupTable groupTableData={currentTableData} isDesktop={isDesktop} />
      </div>
      <div
        style={{ width: isDesktop ? " 75%" : "105%", paddingBottom: "40px" }}
      >
        {" "}
        {currentFixtures.map((match, index) => (
          <FixtureTileGroup
            setCurrentModalMatch={setCurrentModalMatch}
            setIsModalOpen={setIsModalOpen}
            match={match}
            key={`key-${match.associationId}-group-${index}`}
            showDate={
              !isSameDay(new Date(match.kickOffTime?.date || ""), new Date())
            }
            bets={bets.filter((bet) => bet.matchId === match.id)}
            isDesktop={isDesktop}
          />
        ))}{" "}
      </div>
    </div>
  );
};

export default FixtureGroup;

export interface FixtureTileProps {
  showDate?: boolean;
  bets: Bet[];
  match: Match;
  setCurrentModalMatch: React.Dispatch<React.SetStateAction<Match>>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDesktop?: boolean;
}

export const FixtureTileGroup: React.FC<FixtureTileProps> = ({
  showDate,
  bets,
  match,
  setCurrentModalMatch,
  setIsModalOpen,
  isDesktop,
}) => {
  const isBet = bets.length > 0;
  function handleClick() {
    setCurrentModalMatch(match);
    setIsModalOpen(true);
  }
  const isActive = new Date(match.kickOffTime?.dateTime || "").getTime() + TWO_HOURS > new Date().getTime();
  return (
    <Card
      style={{
        backgroundColor: "#172245",
        width: "100%",
        height: "100%",
        maxHeight: "100px",
        borderRadius: "20px",
        marginTop: "20px",
        paddingBottom: isBet ? "30px" : undefined,
      }}
      className={isActive ? "hover-fixture-tile": undefined}
      onClick={isActive ? () => handleClick() : undefined}      
    >
      {isBet && (
        <FixtureTileBetHeader
          betAwayScore={bets[0].awayTeamScoreBet}
          betHomeScore={bets[0].homeTeamScoreBet}
          isDesktop={isDesktop}
        />
      )}
      <FixtureTileBody
        isBet={isBet}
        homeTeamName={match.homeTeam.internationalName}
        awayTeamName={match.awayTeam.internationalName}
        date={match.kickOffTime?.dateTime || ""}
        showDate={showDate}
        mBot="60px"
        isPlayed={match.wasPlayed}
        isDesktop={isDesktop}
        match={match}
      />
    </Card>
  );
};
