import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import React from "react";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { useUser } from "../../common/contexts/userContext";
import BetOutcomeIcon from "../../Mobile/components/BetOutcomeIcon";
import { League } from "../../types/League";
import { useNavigate } from "react-router-dom";
import { User } from "../../types/User";
import UploadPhoto from "../../Mobile/MobileProfile/UploadPhoto";

interface BrowserProfileProps {}

const BrowserProfile: React.FC<BrowserProfileProps> = () => {
  const { user } = useUser();

  return (
    <>
      {user.lastFiveBets?.length === 0 ||
      user.username === "" ||
      user.leagues.length === 0 ? (
        <CircularProgress />
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            overflow: "hidden",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card
            style={{
              height: "80%",
              width: "33%",
              borderRadius: "6%",
              minWidth: "400px",
              minHeight: "600px",
              margin: "2%",
              border: "3px solid #F9BF4B",
            }}
          >
            <CardContent
              style={{
                marginTop: "5%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h4"
                fontFamily={"monospace"}
                fontWeight={600}
              >
                {" "}
                Hello {user.username}!{" "}
              </Typography>
              <UploadPhoto buttonLabel="Upload a profile photo" isDesktop />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "80%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ marginTop: "20px", fontSize: "24px" }}>
                    {" "}
                    Your Points
                  </Typography>
                  <Typography variant="h2"> {user.score.points} </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Typography style={{ marginTop: "20px", fontSize: "24px" }}>
                    Last 5 bets
                  </Typography>
                  <div>
                    {user.lastFiveBets?.length &&
                      user.lastFiveBets?.map((item, index) => (
                        <BetOutcomeIcon
                          outcome={item}
                          key={`${index}-beticon-profile`}
                          styles={{ width: "40px", height: "40px" }}
                        />
                      ))}
                    {!user.lastFiveBets?.length && (
                      <Typography
                        style={{ paddingTop: "20px", fontSize: "18px" }}
                      >
                        No valid bets yet :({" "}
                      </Typography>
                    )}
                  </div>
                </div>
              </div>

              {/* {user.lastFiveBets && user.lastFiveBets.length === 5 && (
            <Typography
              variant="h5"
              fontWeight={600}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginTop: "10px",
              }}
            >
              {getBettingLabel(user.lastFiveBets)}
            </Typography>
          )} */}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {positionLeagues(user)}
              </div>
            </CardContent>
          </Card>
        </div>
      )}
    </>
  );
};

export default BrowserProfile;

function getBettingLabel(results: number[]) {
  const result = results.reduce((a, b) => a + b, 0);
  if (result >= 4 && result <= 7) {
    return (
      <>
        {" "}
        You are betting okay!{" "}
        <InsertEmoticonIcon
          style={{ height: "35px", width: "35px", color: "lightorange" }}
        />{" "}
      </>
    );
  } else if (result < 4) {
    return (
      <>
        {" "}
        You can do better ...{" "}
        <SentimentVeryDissatisfiedIcon
          style={{ height: "35px", width: "35px", color: "lightorange" }}
        />{" "}
      </>
    );
  } else if (result > 7) {
    return (
      <>
        {" "}
        Your bets are perfect!!!{" "}
        <AutoAwesomeIcon
          style={{ height: "35px", width: "35px", color: "lightorange" }}
        />{" "}
      </>
    );
  }
}

const positionLeagues = (user: User) => (
  <React.Fragment>
    <CardContent
      className="cardProfile"
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography
        variant="h5"
        component="div"
        style={{ display: "flex", justifyContent: "center" }}
      >
        Your Leagues
      </Typography>
      <div style={{ width: "85%", height: "200px", overflowY: "auto" }}>
        {user.leagues.map((league) => (
          <LeagueTile league={league} key={`${league.id} - leagues-profile`} />
        ))}
      </div>
    </CardContent>
  </React.Fragment>
);

const LeagueTile: React.FC<{ league: League }> = ({ league }) => {
  const navigate = useNavigate();
  function handleClick(league: League) {
    navigate("/ranking", {
      state: { leagueOption: { label: league.name, leagueId: league.id } },
    });
  }
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #525252",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      <Typography fontFamily={"serif"}>{league.name}</Typography>
      <Button style={{}} onClick={() => handleClick(league)}>
        {" "}
        Check{" "}
      </Button>
    </div>
  );
};
