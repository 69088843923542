import React from "react";
import { Bet } from "../../types/Bet";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";
import { CircleFlag } from "react-circle-flags";
import CountryDict from "../../utils/countryDict";
import { getDateFromDate, getLocalTimeFromDate } from "../../utils/timeUtils";
import BetOutcomeIcon from "../components/BetOutcomeIcon";
import { useFixtures } from "../../common/contexts/fixturesContext";
import { TWO_HOURS } from "../components/FixtureTile/FixtureTile";

interface BetCardProps {
  bet: Bet;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentBet: React.Dispatch<React.SetStateAction<Bet>>;
}

const BetCard: React.FC<BetCardProps> = ({ bet, setIsOpen, setCurrentBet }) => {
  function handleClick(bet: Bet) {
    setCurrentBet(bet);
    setIsOpen(true);
  }
  const { matches } = useFixtures();
  const currentMatch = matches.filter((match) => match.id === bet.matchId)[0];
  const kickOffTime = new Date(
    (currentMatch.kickOffTime?.dateTime || "") + "Z"
  );
  const now = new Date();

  const isActive = kickOffTime.getTime() > now.getTime();

  return (
    <Card
      sx={{
        width: 345,
        borderRadius: "10px",
        background: "#212e3f",
        margin: "20px 0",
      }}
    >
      <CardActionArea>
        <CardContent style={{ color: "#DADADA" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            {currentMatch.matchType.type === "Group" ? (
              <Typography gutterBottom variant="h6" component="div">
                {currentMatch.matchType.type} {currentMatch.matchType.value}
              </Typography>
            ) : (
              <Typography variant="h6">
                {currentMatch.matchType.value}
              </Typography>
            )}

            <Typography>
              {getDateFromDate(currentMatch.kickOffTime?.dateTime || "")}{" "}
              {getLocalTimeFromDate(currentMatch.kickOffTime?.dateTime || "")}
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                width: "75px",
                marginRight: "15px",
                fontSize: "15px",
                textAlign: "right",
              }}
            >
              {currentMatch.homeTeam.internationalName}
            </Typography>
            <CircleFlag
              countryCode={
                CountryDict.get(currentMatch.homeTeam.internationalName) ||
                "Poland"
              }
              height={40}
            />
            <Typography variant="h6" style={{ marginLeft: "10px" }}>
              {" "}
              {bet.homeTeamScoreBet}
            </Typography>
            <span style={{ margin: "0 10px" }}> - </span>
            <Typography variant="h6" style={{ marginRight: "10px" }}>
              {" "}
              {bet.awayTeamScoreBet}
            </Typography>
            <CircleFlag
              countryCode={
                CountryDict.get(currentMatch.awayTeam.internationalName) ||
                "Poland"
              }
              height={40}
            />
            <Typography
              style={{
                width: "75px",
                marginLeft: "15px",
                fontSize: "15px",
              }}
            >
              {currentMatch.awayTeam.internationalName}
            </Typography>
          </div>
          <div
            style={{
              width: "100%",
              textAlign: "right",
              paddingTop: "20px",
              color: "#fa0",
              display: "flex",
              alignItems: "center",
              gap: "20px",
              justifyContent: bet.isActive ? "flex-end" : "space-between",
            }}
          >
            {bet.isActive ? (
              <Button
                disabled={!isActive || !bet.isActive}
                onClick={isActive ? () => handleClick(bet) : undefined}
                style={{
                  background: "#40E0D0",
                  color: "#061237",
                  textTransform: "none",
                  fontWeight: "600",
                  padding: "0 10px",
                }}
              >
                {" "}
                Bet{" "}
              </Button>
            ) : (
              <ShowPoints
                betNumberOfPoints={bet.points}
                betOutcome={bet.betResult}
              />
            )}

            {currentMatch.wasPlayed && (
              <Typography>
                Result: {currentMatch.homeScoreRegular}-
                {currentMatch.awayScoreRegular}
              </Typography>
            )}
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default BetCard;

interface ShowPointsProps {
  betOutcome: number;
  betNumberOfPoints: number;
}

export const ShowPoints: React.FC<ShowPointsProps> = ({
  betOutcome,
  betNumberOfPoints,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        color:
          betOutcome === 0
            ? "##FA3344"
            : betOutcome === 1
            ? "#33FA44"
            : "#66DA77",
      }}
    >
      {" "}
      <BetOutcomeIcon outcome={betOutcome} /> {betNumberOfPoints} points{" "}
    </div>
  );
};
