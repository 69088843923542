import NextButton from "../NextButton";
import { TextField, Typography } from "@mui/material";
import { UserInfo } from "../MobileWizard";
import "../../MobileSign/MobileSign.scss";
import validator from "validator";

interface EmailStepProps {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setUserInfo: React.Dispatch<React.SetStateAction<UserInfo>>;
  userInfo: UserInfo;
  isDesktop?: boolean;
}

const EmailStep: React.FC<EmailStepProps> = ({
  setActiveStep,
  setUserInfo,
  userInfo,
  isDesktop,
}) => {
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    // #TODO validate email otherwise show modal
    setUserInfo((prev) => ({ ...prev, email: event.target.value }));
  }
  return (
    <div className="stepBody" style={{height: isDesktop ? '70vh' : undefined}}>
      <div style={{ width: "100%"}}>
        <Typography
          style={{ marginBottom: "50px", marginTop: "20px" }}
          variant="h4"
        >
          {" "}
          What's your Email?
        </Typography>
        <Typography style={{ marginBottom: "10px" }}> Email address </Typography>
        <TextField
          style={{ width: isDesktop ? "50%" : "100%" }}
          variant="outlined"
          InputProps={{ className: "customedInput" }}
          value={userInfo.email}
          onChange={handleChange}
          name="email"
          placeholder="Email address"
          type="email"
        />
      </div>
      {!validator.isEmail(userInfo.email) && userInfo.email.length > 5 ? (
        <Typography variant="h4"> Email is incorrect </Typography>
      ) : null}
      <NextButton
        label="Next"
        setActiveStep={setActiveStep}
        disabled={!validator.isEmail(userInfo.email)}
      ></NextButton>
    </div>
  );
};

export default EmailStep;
