import React, { useState, useRef, useEffect } from "react";
import { Avatar, Button } from "@mui/material";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useUser } from "../../common/contexts/userContext";
import { API_URL } from "../../utils/api";

interface UploadPhotoProps {
  buttonLabel: string;
  isDesktop?: boolean;
}

const UploadPhoto: React.FC<UploadPhotoProps> = ({
  buttonLabel,
  isDesktop,
}) => {
  const ref = useRef<HTMLInputElement>(null);

  const { user, setUser } = useUser();

  const [imgBlobLink, setImgBlobLink] = useState<string>(user.avatarUrl);
  async function sendRequest(uploadedImage: any) {
    const formData = new FormData();
    formData.append("File", uploadedImage);
    formData.append("UserId", user.id);

    const postRequestOptions = {
      method: "POST",
      body: formData,
    };

    const response = await fetch(
      API_URL + "/api/Image/Avatar",
      postRequestOptions
    );
    return await response.json();
  }

  const uploadFile = async (e: any) => {
    // console.log(e.target.files);
    let uploadedImage = e.target.files[0];
    if (validateFileType(uploadedImage.name)) {
      const resp = sendRequest(uploadedImage);
      resp.then((data) => setImgBlobLink(data.avatarUrl));
    }
  };

  const validateFileType = (fileName: string) => {
    var extFile = getExt(fileName);
    if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
      return true;
    } else {
      alert("Only jpg/jpeg and png files are allowed!");
      return false;
    }
  };

  const getExt = (fileName: string) => {
    var idxDot = fileName.lastIndexOf(".") + 1;
    return fileName.substr(idxDot, fileName.length).toLowerCase();
  };

  useEffect(() => {
    setUser((prev) => ({ ...prev, avatarUrl: imgBlobLink }));
  }, [imgBlobLink, setUser]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Avatar
        src={imgBlobLink}
        sx={{
          height: isDesktop ? "200px" : "125px",
          width: isDesktop ? "200px" : "125px",
          border: "#FAFAFA solid 2px",
          marginBottom: "15px",
          marginTop: isDesktop ? "10px" : undefined,
        }}
        alt="User Avatar"
      ></Avatar>
      {imgBlobLink === "" && (
        <>
          <UploadButton
            buttonLabel={buttonLabel}
            onClick={() => ref.current?.click()}
          />
          <input
            id="filePicker"
            style={{ margin: "2vh", display: "none" }}
            type="file"
            onChange={uploadFile}
            accept="image/*"
            ref={ref}
          />
        </>
      )}
    </div>
  );
};

export default UploadPhoto;

export const UploadButton: React.FC<UploadButtonProps> = ({
  onClick,
  buttonLabel,
}) => {
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<CloudUploadIcon />}
      onClick={() => onClick()}
    >
      {buttonLabel}
    </Button>
  );
};

interface UploadButtonProps {
  onClick: any;
  buttonLabel: string;
}
