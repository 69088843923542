import React, { useState } from "react";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import EmailStep from "./steps/EmailStep";
import PasswordStep from "./steps/PasswordStep";
import NameStep from "./steps/NameStep";
import PreferencesStep, { TeamInfo } from "./steps/PreferencesStep";
import "./MobileWizard.scss";
import { useNavigate } from "react-router-dom";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import DiscardChangesModal from "../components/DiscardChangesModal";
import { API_URL } from "../../utils/api";

interface MobileWizardProps {
  isDesktop?: boolean;
}

export const MobileWizard: React.FC<MobileWizardProps> = ({ isDesktop }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [userInfo, setUserInfo] = useState<UserInfo>(initInfo);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const confirmAction = () => {
    navigate(-1);
    setOpenModal(false);
  };

  function createAccount() {
    const createUserBody = {
      username: userInfo.name,
      email: userInfo.email,
      password: userInfo.password,
      favouriteTeams: userInfo.preferences,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(createUserBody),
    };

    function correctCreate(){
      navigate("/login")
      alert(`User ${userInfo.name} created successfully!`)
    }

    fetch(API_URL + "/api/Authorization/Register", requestOptions).then((response) =>
      response.ok
        ? correctCreate()
        : alert("User couldn't be created - try once more.")
    );
  }

  function handleBackClick() {
    if (activeStep > 0) {
      setActiveStep((prev) => prev - 1);
    } else {
      setOpenModal(true);
    }
  }
  return (
    <div
      style={{ width: isDesktop ? "100%" : undefined }}
      className={
        activeStep !== 3
          ? "mobileWizardPageLayout"
          : "mobileWizardPageLayoutPreferences"
      }
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
          alignItems: "center",
        }}
      >
        <span onClick={handleBackClick}>
          <KeyboardArrowLeft fontSize="large" />
        </span>
        <div style={{ color: "#A2AEBB" }}> Step {activeStep + 1} of 4</div>
        <KeyboardArrowRight fontSize="large" style={{ opacity: "0" }} />
      </div>
      <LinearProgress
        value={(activeStep + 1) * 25}
        variant="determinate"
        sx={{
          color: "white",
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: "#7B8595",
          },
          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: "#FFFFFF",
          },
        }}
      />
      {activeStep === 0 && (
        <div style={{ height: "95%", padding: "0px 15px" }}>
          <EmailStep
            setActiveStep={setActiveStep}
            setUserInfo={setUserInfo}
            userInfo={userInfo}
            isDesktop={isDesktop}
          />
          <DiscardChangesModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            confirmAction={confirmAction}
          />
        </div>
      )}
      {activeStep === 1 && (
        <div style={{ height: "95%", padding: "0px 15px" }}>
          <PasswordStep
            setActiveStep={setActiveStep}
            setUserInfo={setUserInfo}
            userInfo={userInfo}
            isDesktop={isDesktop}
          />
        </div>
      )}
      {activeStep === 2 && (
        <div style={{ height: "95%", padding: "0px 15px" }}>
          <NameStep
            setActiveStep={setActiveStep}
            setUserInfo={setUserInfo}
            userInfo={userInfo}
            isDesktop={isDesktop}
          />
        </div>
      )}
      {activeStep === 3 && (
        <div style={{ padding: "0 15px", height: "80%" }}>
          <PreferencesStep
            createAccount={createAccount}
            setUserInfo={setUserInfo}
            userInfo={userInfo}
            isDesktop={isDesktop}
          />
        </div>
      )}
    </div>
  );
};

export interface UserInfo {
  email: string;
  name: string;
  password: string;
  preferences: TeamInfo[];
}

export const initInfo = {
  email: "",
  name: "",
  password: "",
  preferences: [],
};
