import { Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const HomePageHeader: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        width: "100%",
        background: "#132045",
        paddingBottom: '8px',
        borderBottom: '1px solid #FAFAFA'
      }}
    >
      <div
        style={{
          padding: "20px 0px 0 10px",
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <img
          src="./images/logo_cropped.png"
          alt="ball typer logo"
          style={{ height: "80px" }}
          // onClick={() => navigate("/")}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "80px",
          }}
        >
          <Typography
            // onClick={() => navigate("/")}
            variant="h4"
            fontFamily={"monospace"}
            style={{ color: "#FAFAFA" }}
          >
            Typer 2024
          </Typography>
          <Typography
            style={{ marginBottom: "5px", color: "#FAFAFA" }}
            fontFamily={"cursive"}
          >
            {" "}
            Money comes from betting ~ C. Ronaldo
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default HomePageHeader;
