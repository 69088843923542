import { MobileBody } from "./MobileBody/MobileBody";
import { MobileFooterNavbar } from "./MobileFooterNavbar/MobileFooterNavbar";
import "./MobileApp.scss";
import { ErrorBoundary } from "react-error-boundary";
import ErrorComponent from "./components/ErrorComponent";
import { useUser } from "../common/contexts/userContext";
import { useNavigate } from "react-router-dom";
import HomePageHeader from "./HomePageHeader";
import { jwtDecode } from "jwt-decode";
import { API_URL } from "../utils/api";
import { useEffect } from "react";
import { Match } from "../types/Match";
import { useFixtures } from "../common/contexts/fixturesContext";
import { useBets } from "../common/contexts/betContext";

export const MobileApp = () => {
  const navigate = useNavigate(); // used to re-render component and actualize isNavbarVisibile variable
  const isNavbarVisible =
    localStorage.getItem("isSigned") === "true" && 
    !["/createAccount", "/login"].includes(window.location.pathname); // possibly remove '/' in future and change userSigned for real
  const isHeaderVisible =
    localStorage.getItem("isSigned") === "true" &&
    !["/login", "/createAccount", "/", "/createLeague"].includes(
      window.location.pathname
    );

  const { user, setUser } = useUser();
  const { setMatches } = useFixtures();
  const { setBets } = useBets();

  async function updateContexts(token: any) {
    const requestOptionFixtures = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
      },
    };
    const responseFixtures = await fetch(
      API_URL + `/api/Match/all`,
      requestOptionFixtures
    );
    const dataFixtures = await responseFixtures.json();

    setMatches(
      dataFixtures.filter((match: Match) => match.homeTeam && match.awayTeam)
    );
    // Bet Context
    const requestOptionBets = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
      },
    };
    const responseBets = await fetch(
      API_URL + `/api/Bet/All/User/${token.Id}`, // #TODO get user ID
      requestOptionBets
    );
    const dataBets = await responseBets.json();
    setBets(dataBets);

    const responseUser = await fetch(
      API_URL + `/api/User/${token.Id}`,
      requestOptionBets
    );
    const dataUser = await responseUser.json();
    setUser(dataUser);
    // navigate("/profile");
  }

  useEffect(() => {
    if (window.localStorage.getItem("isSigned") === "true" && !user.id) {
      const tokenDecoded = jwtDecode(
        window.localStorage.getItem("accessToken") || ""
      );
      updateContexts(tokenDecoded);
    }
  }, []);
  return (
    <div className="mobileAppLayout">
      {isHeaderVisible && <HomePageHeader />}
      <ErrorBoundary
        FallbackComponent={ErrorComponent}
        onReset={() => navigate("/")}
      >
        <MobileBody />
      </ErrorBoundary>
      {isNavbarVisible ? <MobileFooterNavbar /> : null}
    </div>
  );
};
