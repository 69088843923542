import { useEffect, useState } from "react";
import MobileBetsFilterPanel from "./MobileBetsFilterPanel";
import MobileBetsBody from "./MobileBetsBody";
import { useBets } from "../../common/contexts/betContext";
import { Bet } from "../../types/Bet";
import { CircularProgress } from "@mui/material";

export const MobileBets = () => {
  const [currentFilters, setCurrentFilters] = useState<BetFilter[]>([]);
  const { bets } = useBets();
  const [betsCurrent, setBetsCurrent] = useState<Bet[]>(bets);
  function onFilterClick(value: BetFilter) {
    if (!currentFilters.includes(value)) {
      setCurrentFilters((prev) => [...prev, value]);
    } else {
      setCurrentFilters((prev) => prev.filter((filter) => filter !== value));
    }
  }

  useEffect(() => {
    let filteredBets = bets;
    if (currentFilters.includes("Correct")) {
      filteredBets = filteredBets.filter(
        (bet) => bet.betResult === 1 || bet.betResult === 2
      );
    }
    if (currentFilters.includes("Wrong")) {
      filteredBets = filteredBets.filter((bet) => bet.betResult === 0);
    }

    if (
      !(currentFilters.includes("Past") && currentFilters.includes("Ongoing"))
    ) {
      if (currentFilters.includes("Past")) {
        filteredBets = filteredBets.filter((bet) => !bet.isActive);
      }
      if (currentFilters.includes("Ongoing")) {
        filteredBets = filteredBets.filter((bet) => bet.isActive);
      }
    }

    setBetsCurrent(filteredBets);
  }, [bets, currentFilters]);

  return (
    <div
      style={{
        width: "100%",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        paddingBottom: "20px",
      }}
    >
      <MobileBetsFilterPanel
        onFilterClick={onFilterClick}
        currentFilters={currentFilters}
      />

      {bets.length === 0 ? (
        <CircularProgress />
      ) : (
        <MobileBetsBody bets={betsCurrent} />
      )}
    </div>
  );
};

export type BetFilter = "Past" | "Ongoing" | "Correct" | "Wrong";
