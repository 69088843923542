import { Divider, Link } from "@mui/material";
import "./MobileSign.scss";
import { MobileLoginPanel } from "./MobileLoginPanel";
import { MobileAuthenticationPanel } from "./MobileAuthenticationPanel";
interface MobileSignInProps {}

export const MobileSignIn: React.FC<MobileSignInProps> = ({}) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", padding: "25px" }}>
      <MobileLoginPanel />
      <Divider
        style={{ margin: "30px 0", color: "#FFF" }}
        sx={{
          "&::before, &::after": {
            bgcolor: "#A2AEBB",
          },
        }}
      >
        {" "}
        or{" "}
      </Divider>
      <MobileAuthenticationPanel />
      <Link
        style={{
          width: "100%",
          textAlign: "center",
          color: "#00B0FF",
          textDecoration: "none",
          marginTop: "20px",
        }}
        href="/createAccount"
      >
        {" "}
        Create an account{" "}
      </Link>
    </div>
  );
};
