import { useEffect, useState } from "react";
import { API_URL } from "../utils/api";
import { Button, Typography } from "@mui/material";
import { Download } from "@mui/icons-material";
import { Link } from "react-router-dom";
import moment from "moment";

interface BetExcelBlobFile {
  name: string;
  url: string;
  dateCreated: string;
}

async function getData() {
  const requestOptionFixtures = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  };
  const responseFixtures = await fetch(
    API_URL + `/api/Score/Bets/ExcelsList`,
    requestOptionFixtures
  );
  const dataFixtures = await responseFixtures.json();
  return dataFixtures;
}

const ExcelData = () => {
  const [dataExcel, setDataExcel] = useState<BetExcelBlobFile[]>([]);
  useEffect(() => {
    getData().then((data) =>
      setDataExcel(
        data.sort(
          (a: BetExcelBlobFile, b: BetExcelBlobFile) =>
            Date.parse(b.dateCreated) - Date.parse(a.dateCreated)
        )
      )
    );
  }, []);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography variant="h3" color="#FAFAFA" style={{ marginTop: "30px" }}>
        {" "}
        Excels with users bets
      </Typography>
      <div style={{ width: "100%", marginTop: "40px" }}>
        {dataExcel.length > 0 &&
          dataExcel.map((item) => (
            <Link to={item.url} key={`lolo${item.name}`}>
              <Button
                color="info"
                variant="contained"
                style={{ margin: "20px" }}
                endIcon={<Download />}
              >
                {moment(item.dateCreated).toLocaleString()}
              </Button>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default ExcelData;
