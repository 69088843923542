import React, { createContext, useState, ReactNode, useContext } from 'react';
import { Match } from '../../types/Match'; // Adjust the import path as necessary
import { KickOffTime } from '../../types/KickOffTime';
import { Matchday } from '../../types/MatchDay';
import { Team } from '../../types/Team';

interface MatchContextProps {
  matches: Match[];
  setMatches: React.Dispatch<React.SetStateAction<Match[]>>;
}

const MatchContext = createContext<MatchContextProps | undefined>(undefined);

export const MatchProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [matches, setMatches] = useState<Match[]>([emptyMatch]);

  return (
    <MatchContext.Provider value={{ matches, setMatches }}>
      {children}
    </MatchContext.Provider>
  );
};

export const useFixtures = (): MatchContextProps => {
  const context = useContext(MatchContext);
  if(!context){
    throw new Error  ("context of fixtures is not created");
  }
  return context;
}


const emptyTeam: Team = {
  associationId: '',
  countryCode: '',
  internationalName: '',
  organizationId: '',
  teamCode: '',
  typeTeam: '',
  id: 0
};

const emptyMatchday: Matchday = {
  matchdayId: 0,
  competitionId: '',
  dateFrom: '',
  dateTo: '',
  format: '',
  longName: '',
  name: '',
  roundId: '',
  seasonYear: '',
  sequenceNumber: '',
  type: ''
};

const emptyKickOffTime: KickOffTime = {
  kickOffTimeId: 0,
  date: '',
  dateTime: '',
  utcOffsetInHours: 0
};

const emptyMatch: Match = {
  awayTeam: emptyTeam,
  awayTeamId: 0,
  homeTeam: emptyTeam,
  homeTeamId: 0,
  kickOffTime: emptyKickOffTime,
  matchday: emptyMatchday,
  associationId: '',
  id: 0, 
  matchType: {type: "Group", value: "A"},
  wasPlayed: false,
  homeScoreRegular: 0,
  awayScoreRegular: 0,
};