import { Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../utils/api";
import { Match } from "../../types/Match";
import { FixtureTileGroup } from "./FixtureGroup";
import { useBets } from "../../common/contexts/betContext";
import BetModal from "../components/BetModal";
import { mockMatch } from "../MobileBets/MobileBetsBody";

interface FixtureKnockoutProps {
  isDesktop?: boolean;
}

async function getAllMatches() {
  const requestOptionFixtures = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  };
  const responseFixtures = await fetch(
    API_URL + `/api/Match/Knockout`,
    requestOptionFixtures
  );
  const dataFixtures = await responseFixtures.json();
  return dataFixtures;
}

const FixtureKnockout: React.FC<FixtureKnockoutProps> = ({ isDesktop }) => {
  const [roundOf16Matches, setRoundOf16Matches] = useState<Match[]>([]);
  const [quarterFinalsMatches, setQuarterFinalsMatches] = useState<Match[]>([]);
  const [semiFinalMatches, setSemiFinalMatches] = useState<Match[]>([]);
  const [finalMatches, setFinalMatches] = useState<Match[]>([]);
  const { bets } = useBets();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentModalMatch, setCurrentModalMatch] = useState<Match>(mockMatch);
  useEffect(() => {
    getAllMatches().then((data) => {
      setRoundOf16Matches(
        data.filter((item: Match) => item.matchType.value === "Round of 16")
      );
      setQuarterFinalsMatches(
        data.filter((item: Match) => item.matchType.value === "Quarter-finals")
      );
      setSemiFinalMatches(
        data.filter((item: Match) => item.matchType.value === "Semi-finals")
      );
      setFinalMatches(
        data.filter((item: Match) => item.matchType.value === "Final")
      );
    });
  }, []);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        color: "#FAFAFA",
      }}
    >
      {roundOf16Matches.length > 0 && (
        <div
          style={{
            width: isDesktop ? "80%" : '95%',
            marginBottom: isDesktop ? '40px' : '20px',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Divider
            style={{ marginTop: '30px', color: "#FFF", width: "100%" }}
            sx={{
              "&::before, &::after": {
                bgcolor: "#A2AEBB",
              },
            }}
          >
            {" "}
            <Typography
              variant={isDesktop ? "h4" : "h6"}
              // style={{ marginTop: "50px" }}
            >
              {" "}
              Round of 16
            </Typography>
          </Divider>
          {roundOf16Matches.map((match) => (
            <FixtureTileGroup
              match={match}
              showDate
              key={`fixture knockout - ${match.associationId}`}
              isDesktop={isDesktop}
              bets={bets.filter((bet) => bet.matchId === match.id)}
              setIsModalOpen={setIsModalOpen}
              setCurrentModalMatch={setCurrentModalMatch}
            />
          ))}
        </div>
      )}
       {quarterFinalsMatches.length > 0 && (
        <div
          style={{
            width: isDesktop ? "80%" : '95%',
            marginBottom: isDesktop ? '40px' : '20px',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Divider
            style={{ marginTop: '30px', color: "#FFF", width: "100%" }}
            sx={{
              "&::before, &::after": {
                bgcolor: "#A2AEBB",
              },
            }}
          >
            {" "}
            <Typography
              variant={isDesktop ? "h4" : "h6"}
              // style={{ marginTop: "50px" }}
            >
              {" "}
              Quarter-Finals
            </Typography>
          </Divider>
          {quarterFinalsMatches.map((match) => (
            <FixtureTileGroup
              match={match}
              showDate
              key={`fixture knockout - ${match.associationId}`}
              isDesktop={isDesktop}
              bets={bets.filter((bet) => bet.matchId === match.id)}
              setIsModalOpen={setIsModalOpen}
              setCurrentModalMatch={setCurrentModalMatch}
            />
          ))}
        </div>
      )}
      {semiFinalMatches.length > 0 && (
        <div
          style={{
            width: isDesktop ? "80%" : '95%',
            marginBottom: isDesktop ? '40px' : '20px',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Divider
            style={{ marginTop: '30px', color: "#FFF", width: "100%" }}
            sx={{
              "&::before, &::after": {
                bgcolor: "#A2AEBB",
              },
            }}
          >
            {" "}
            <Typography
              variant={isDesktop ? "h4" : "h6"}
              // style={{ marginTop: "50px" }}
            >
              {" "}
              Semi-Finals
            </Typography>
          </Divider>
          {semiFinalMatches.map((match) => (
            <FixtureTileGroup
              match={match}
              showDate
              key={`fixture knockout - ${match.associationId}`}
              isDesktop={isDesktop}
              bets={bets.filter((bet) => bet.matchId === match.id)}
              setIsModalOpen={setIsModalOpen}
              setCurrentModalMatch={setCurrentModalMatch}
            />
          ))}
        </div>
      )}
      {finalMatches.length > 0 && (
        <div
          style={{
            width: isDesktop ? "80%" : '95%',
            marginBottom: isDesktop ? '40px' : '20px',
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Divider
            style={{ marginTop: '30px', color: "#FFF", width: "100%" }}
            sx={{
              "&::before, &::after": {
                bgcolor: "#A2AEBB",
              },
            }}
          >
            {" "}
            <Typography
              variant={isDesktop ? "h4" : "h6"}
              // style={{ marginTop: "50px" }}
            >
              {" "}
              Final
            </Typography>
          </Divider>
          {finalMatches.map((match) => (
            <FixtureTileGroup
              match={match}
              showDate
              key={`fixture knockout - ${match.associationId}`}
              isDesktop={isDesktop}
              bets={bets.filter((bet) => bet.matchId === match.id)}
              setIsModalOpen={setIsModalOpen}
              setCurrentModalMatch={setCurrentModalMatch}
            />
          ))}
        </div>
      )}
      <BetModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        match={currentModalMatch}
        isDesktop={isDesktop}
      />
    </div>
  );
};

export default FixtureKnockout;
