import React, { useRef, useState } from "react";
import { Avatar, Button, Typography } from "@mui/material";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

interface UploadPhotoProps {
  buttonLabel: string;
  imgBlobLink: string;
  setUploadedImage: React.Dispatch<React.SetStateAction<any>>;
  uploadedImage: any;
  isDesktop?: boolean;
}

const UploadPhoto: React.FC<UploadPhotoProps> = ({
  buttonLabel,
  imgBlobLink,
  setUploadedImage,
  uploadedImage,
  isDesktop
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState("")
  const uploadFile = async (e: any) => {
    const img = e.target.files[0];
    if (validateFileType(img.name)) {
      setUploadedImage(img);
      setFileName(img.name);
    }
  };

  const validateFileType = (fileName: string) => {
    var extFile = getExt(fileName);
    if (extFile === "jpg" || extFile === "jpeg" || extFile === "png") {
      return true;
    } else {
      alert("Only jpg/jpeg and png files are allowed!");
      return false;
    }
  };

  const getExt = (fileName: string) => {
    var idxDot = fileName.lastIndexOf(".") + 1;
    return fileName.substr(idxDot, fileName.length).toLowerCase();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        width: isDesktop ? '30%' : '100%',
        // justifyContent: "center",
      }}
    >
      {/* <img src={uploadedImage ? uploadedImage : ""} alt="asfsafas" /> */}
      {/* <Avatar
        component={uploadedImage ? uploadedImage.name : ""}
        sx={{
          height: "175px",
          width: "175px",
          border: "#FAFAFA solid 2px",
          marginBottom: "15px",
        }}
        alt="League Avatar"
      ></Avatar> */}
      {fileName === "" ? (
        <>
          <UploadButton
            buttonLabel={buttonLabel}
            onClick={() => ref.current?.click()}
          />
          <input
            id="filePickerLeague"
            style={{ margin: "2vh", display: "none" }}
            type="file"
            onChange={uploadFile}
            accept="image/*"
            ref={ref}
          />
        </>
      ) : (
        <Typography> Image {fileName} uploaded successfully!</Typography>
      )}
    </div>
  );
};

export default UploadPhoto;

export const UploadButton: React.FC<UploadButtonProps> = ({
  onClick,
  buttonLabel,
}) => {
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<CloudUploadIcon />}
      onClick={() => onClick()}
    >
      {buttonLabel}
    </Button>
  );
};

interface UploadButtonProps {
  onClick: any;
  buttonLabel: string;
}
