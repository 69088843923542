import React, { useRef } from "react";
import "./styles.scss";

export type ModalValue = {
  homeScore: number | null;
  awayScore: number | null;
};

export const initModalValue = {
  homeScore: null,
  awayScore: null,
};

interface BetModalBodyProps {
  homePrediction?: number;
  awayPrediction?: number;
  modalValue: ModalValue;
  setModalValue: React.Dispatch<React.SetStateAction<ModalValue>>;
  isDesktop?: boolean;
}

const BetModalBody: React.FC<BetModalBodyProps> = ({
  homePrediction,
  awayPrediction,
  modalValue,
  setModalValue,
  isDesktop,
}) => {
  const input1 = useRef<HTMLInputElement | null>(null);
  const input2 = useRef<HTMLInputElement | null>(null);
  function handleInput(ref: React.MutableRefObject<HTMLInputElement | null>) {
    if (ref.current !== null && ref.current.value.length > 1) {
      ref.current.value = ref.current.value[0];
    }
    if (ref.current !== null) {
      ref.current.value = ref.current.value.replace(/[^0-9]/g, "");
    }
  }
  return (
    <div
      style={{
        color: "white",
        width: "100%",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
      }}
    >
      <input
        className="input-score no-spin"
        style={
          isDesktop
            ? {
                width: "100px",
                height: "50px",
                fontSize: "30px",
                borderRadius: "8px",
                textAlign: "center",
                border: input1.current?.value ? undefined : "red 2px solid",
              }
            : {
                border: input1.current?.value ? undefined : "red 1px solid",
              }
        }
        maxLength={2}
        type="number"
        placeholder={homePrediction ? homePrediction.toString() : "0"}
        ref={input1}
        min="-1"
        step="1"
        onInput={() => handleInput(input1)}
        onChange={(e) =>
          setModalValue({
            homeScore: Number(e.target.value),
            awayScore: modalValue.awayScore,
          })
        }
      />
      <span style={{ fontSize: isDesktop ? "45px" : undefined }}>-</span>

      <input
        className="input-score no-spin"
        style={
          isDesktop
            ? {
                width: "100px",
                height: "50px",
                fontSize: "30px",
                borderRadius: "8px",
                textAlign: "center",
                border: input2.current?.value ? undefined : "red 2px solid",
              }
            : {
                border: input1.current?.value ? undefined : "red 1px solid",
              }
        }
        maxLength={2}
        ref={input2}
        type="number"
        min="-1"
        max="9"
        step="1"
        onInput={() => handleInput(input2)}
        placeholder={awayPrediction ? awayPrediction.toString() : "0"}
        onChange={(e) =>
          setModalValue({
            homeScore: modalValue.homeScore,
            awayScore: Number(e.target.value),
          })
        }
      />
    </div>
  );
};

export default BetModalBody;
