import React, { useEffect, useState } from "react";
import LeagueInfo from "./LeagueInfo";
import "./LeagueStyles.scss";
import LeaguesDropdown, {
  LeagueDescription,
  initLeagueDescription,
} from "../components/LeaguesDropdown";
import { API_URL } from "../../utils/api";
import { League } from "../../types/League";

interface SearchLeaguePanelProps {}

const SearchLeaguePanel: React.FC<SearchLeaguePanelProps> = () => {
  const [leaguesLabels, setLeaguesLabels] = useState([initLeagueDescription]);
  const [currentLeagueDescription, setCurrentLeagueDescription] =
    useState<LeagueDescription>(initLeagueDescription);
  const [currentLeague, setCurrentLeague] = useState<League | undefined>(
    undefined
  );
  const [allLeagues, setAllLeagues] = useState<League[]>([]);
  useEffect(() => {
    getAllLeagues().then((data) => {
      const currLeague = data.filter(
        (item: League) => item.name === "General"
      )[0];
      setLeaguesLabels(
        data.map((item: League) => ({
          label: item.name,
          leagueId: item.id,
        }))
      );
      setCurrentLeague(currLeague);
      setAllLeagues(data);
      setCurrentLeagueDescription({
        label: currLeague.name,
        leagueId: currLeague.id,
      });
    });
  }, []);

  useEffect(() => {
    setCurrentLeague(
      allLeagues.filter(
        (league: League) => league.id === currentLeagueDescription.leagueId
      )[0]
    );
  }, [allLeagues, currentLeagueDescription]);
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        paddingTop: "20px",
        flexDirection: "column",
        justifyContent: "center",
        gap: "30px",
        overflowY: "auto",
      }}
    >
      <LeaguesDropdown
        setCurrentLeague={setCurrentLeagueDescription}
        leagueOptions={leaguesLabels}
        width="90%"
        currentLeague={currentLeague?.name}
      />
      <LeagueInfo currentLeague={currentLeague} height="400px"/>
    </div>
  );
};

export default SearchLeaguePanel;

export async function getAllLeagues() {
  const token = window.localStorage.getItem("accessToken");
  const requestOption = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await fetch(API_URL + "/api/League/All", requestOption);
  const data = await response.json();
  return data;
}
