import React, { useEffect, useState } from "react";
import { Match } from "../../types/Match";
import { useFixtures } from "../../common/contexts/fixturesContext";
import { useBets } from "../../common/contexts/betContext";
import { mockMatch } from "../../Mobile/MobileBets/MobileBetsBody";
import moment from "moment";
import FixturePanel from "../../Mobile/MobileFixtures/FixturePanel";
import FixtureMenu from "../../Mobile/MobileFixtures/FixtureMenu";
import { createDaysArray } from "../../Mobile/MobileFixtures/MobileFixtures";
import FixtureBody from "../../Mobile/MobileFixtures/FixtureBody";
import FixtureGroup from "../../Mobile/MobileFixtures/FixtureGroup";
import FixtureKnockout from "../../Mobile/MobileFixtures/FixtureKnockout";
import BetModal from "../../Mobile/components/BetModal";
import { CircularProgress } from "@mui/material";

const BrowserFixture: React.FC = () => {
  const [allFixtures, setAllFixtures] = useState<Match[]>([]);
  const [currentFixtures, setCurrentFixtures] = useState<Match[]>([]);
  const [currentPanel, setCurrentPanel] = useState("Upcoming");
  const [value, setValue] = useState(4); // 4 means today
  const { matches } = useFixtures();
  const { bets } = useBets();

  // modalbet stuff
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [currentModalMatch, setCurrentModalMatch] = useState<Match>(mockMatch);
  function sortFixturesByDay(day: string) {
    setCurrentFixtures(
      allFixtures.filter((match) => match.kickOffTime?.date === day)
    );
  }

  useEffect(() => {
    if (allFixtures.length > 0) {
      sortFixturesByDay(
        moment(Date())
          .add(value - 52, "days")
          .format("YYYY-MM-DD")
      );
    }
  }, [value, allFixtures]);

  useEffect(() => {
    setAllFixtures(matches);
  }, [matches]);

  return (
    <>
      {matches.length === 0 ? (
        <CircularProgress />
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            overflowY: "auto",
            height: "100%",
          }}
        >
          <FixturePanel
            setCurrentPanel={setCurrentPanel}
            currentPanel={currentPanel}
            isDesktop
          />
          {currentPanel === "Upcoming" && (
            <div
              style={{
                width: "75%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <FixtureMenu
                dayLabels={createDaysArray()}
                value={value}
                setValue={setValue}
                isDesktop
              />
              <FixtureBody
                currentFixtures={currentFixtures}
                bets={bets}
                setCurrentModalMatch={setCurrentModalMatch}
                setIsOpen={setIsModalOpen}
                isDesktop
              />
            </div>
          )}
          {currentPanel === "Group Stage" && (
            <div
              style={{
                width: "70%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <FixtureGroup
                setCurrentModalMatch={setCurrentModalMatch}
                setIsModalOpen={setIsModalOpen}
                isDesktop
              />
            </div>
          )}
          {currentPanel === "Knockout" && <FixtureKnockout isDesktop />}
          <BetModal
            isOpen={isModalOpen}
            setIsOpen={setIsModalOpen}
            match={currentModalMatch}
            isDesktop
          />
        </div>
      )}
    </>
  );
};

export default BrowserFixture;
