import React from "react";
import PersonalLeagueInfo from "./PersonalLeagueInfo";
import { useUser } from "../../common/contexts/userContext";
import { LeagueViewTab } from "./MobileLeagues";

interface YourLeaguesPanelProps {
  setManageLeagueId: React.Dispatch<React.SetStateAction<number>>;
  setCurrentView: React.Dispatch<React.SetStateAction<LeagueViewTab>>;
  isDesktop?: boolean;
}

const YourLeaguesPanel: React.FC<YourLeaguesPanelProps> = ({
  setManageLeagueId,
  setCurrentView,
  isDesktop
}) => {
  const { user } = useUser();
  return (
    <div style={{ padding: isDesktop ? undefined : "20px", height: "100%", overflowY: "auto" }}>
      {user.leagues.map((league) => (
        <PersonalLeagueInfo
          key={`${league.id} - search item`}
          leagueInfo={league}
          setManageLeagueId={setManageLeagueId}
          setCurrentView={setCurrentView}
          isAdmin={
            user.leagues.filter((item) => item.adminId=== user.id).length > 0
          }
        />
      ))}
    </div>
  );
};

export default YourLeaguesPanel;