import moment from "moment";

export const getNameOfCurrentDay = () => {
  return moment(Date()).format("dddd");
};

export const getNextFiveDays = () => {
  let daysArray = [];
  for (let i = 0; i < 5; i++) {
    daysArray.push(
      moment(Date())
        .add(i + 1, "days")
        .format("dddd")
    );
  }
  return daysArray;
};

export function getLocalTimeFromDate(dateString: string): string {
  const date = new Date(moment(dateString).add(2, "hour").toLocaleString());
  const hours = date.getHours().toLocaleString().padStart(2, "0");
  const minutes = date.getMinutes().toLocaleString().padStart(2, "0");
  return `${hours}:${minutes}`;
}

export function getDateFromDate(dateString: string): string {
  const date = new Date(dateString);
  const day = date.getDate();
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getMonth()];
  return `${day} ${month}`;
}
