import { jwtDecode } from "jwt-decode";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router";
import "./MobileSign.scss";
import { API_URL } from "../../utils/api";
import { useUser } from "../../common/contexts/userContext";

export interface MobileAuthenticationPanelProps {}

export const MobileAuthenticationPanel: React.FC<
  MobileAuthenticationPanelProps
> = () => {
  const navigate = useNavigate();

  const { setUser } = useUser();

  async function handleGoogleLogin(user: any) {
    const userName = user.given_name + user.family_name;
    const createUserBody = {
      username: userName === "" ? user.email : userName,
      email: user.email,
      avatarUrl: user.picture,
    };

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(createUserBody),
    };

    fetch(API_URL + "/api/Authorization/GoogleLogin", requestOptions).then(
      async (response) =>
        response.ok
          ? onGoogleLoginSuccess(await response.json())
          : alert("User couldn't be created - try once more.")
    );
  }

  const onGoogleLoginSuccess = (data: any) => {
    window.localStorage.setItem("accessToken", data.data.accessToken);
    window.localStorage.setItem("isSigned", "true");
    setUser(data.data.user);
    navigate("/profile");
  };

  const onSuccess = (resp: any) => {
    const user = jwtDecode(resp.credential);
    handleGoogleLogin(user);
  };

  const onError = () => console.log("Login Failed:");

  // const facebookLink = useRef<HTMLAnchorElement | null>(null);

  // useEffect(() => {
  //   if (facebookLink.current) {
  //     facebookLink.current.click()
  //   }
  // }, [facebookLink.current])

  // if (navigator.userAgent.toLowerCase().includes('facebook')) {
  //   return (
  //     <a ref={facebookLink} href={window.location.href} target='_blank' download>Open in browser</a>
  //   )
  // }

  // if (navigator.userAgent.toLowerCase().includes('messenger')) {
  //   return (
  //     <a ref={facebookLink} href={window.location.href} target='_blank' download>Open in browser</a>
  //   )
  // }

  return (
    <div>
      <div className="buttonGoogleStyles">
        <GoogleLogin
          onSuccess={onSuccess}
          onError={onError}
          width={window.innerWidth - 50} // style correctly
        ></GoogleLogin>
      </div>
    </div>
  );
};
