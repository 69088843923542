import { useState } from "react";
import MobileLeaguePanel from "./MobileLeaguePanel";
import SearchLeaguePanel from "./SearchLeaguePanel";
import YourLeaguesPanel from "./YourLeaguesPanel";
import ManageLeaguePanel from "./ManageLeaguePanel";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../common/contexts/userContext";
import debounce from "debounce";

export interface MobileLeaguesProps {
  isUserSigned: boolean;
}

export const MobileLeagues = ({ isUserSigned }: MobileLeaguesProps) => {
  const [currentView, setCurrentView] = useState<LeagueViewTab>("Search");
  const [manageLeagueId, setManageLeagueId] = useState(0);
  const { user } = useUser();
  const showButton =
    user.leagues.filter((league) => league.adminId === user.id).length < 3;

  return (
    <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
      {currentView !== "ManageLeagues" && (
        <MobileLeaguePanel
          setCurrentView={setCurrentView}
          currentView={currentView}
          paddingTop
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {currentView === "Search" && <SearchLeaguePanel />}
        {currentView === "YourLeagues" && (
          <YourLeaguesPanel
            setManageLeagueId={setManageLeagueId}
            setCurrentView={setCurrentView}
          />
        )}
        {currentView === "ManageLeagues" && (
          <ManageLeaguePanel
            manageLeagueId={manageLeagueId}
            setCurrentView={setCurrentView}
          />
        )}

        {currentView !== "ManageLeagues" && showButton && (
          <div style={{ textAlign: "right", paddingRight: "20px" }}>
            <CreateLeagueButton />
          </div>
        )}
      </div>
    </div>
  );
};

export type LeagueViewTab = "Search" | "YourLeagues" | "ManageLeagues";

export const CreateLeagueButton = () => {
  const navigate = useNavigate();

  return (
    <Button
      startIcon={<Add />}
      onClick={debounce(() => navigate("/createLeague"), 300)}
      style={{
        borderRadius: "12.5px",
        background: "#F9BF4B",
        color: "#111",
        fontWeight: "600",
      }}
    >
      Create League
    </Button>
  );
};
