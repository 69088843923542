import React from "react";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";

interface BetOutcomeIconProps {
  outcome: number;
  styles?: any;
}

const BetOutcomeIcon: React.FC<BetOutcomeIconProps> = ({ outcome, styles }) => {
  switch (outcome) {
    case 0:
      return (
        <ClearOutlinedIcon
          style={{ ...styles, color: "#FA3344", margin: "3px" }}
        />
      );
    case 1:
      return (
        <CheckOutlinedIcon
          style={{ ...styles, color: "#33FA44", margin: "3px" }}
        />
      );
    case 2:
      return (
        <DoneAllOutlinedIcon
          style={{ ...styles, color: "#66DA77", margin: "3px" }}
        />
      );
    default:
      return <div></div>;
  }
};

export default BetOutcomeIcon;
