import { Route, Routes } from "react-router-dom";
import { BrowserHomepage } from "./BrowserHomepage/BrowserHomepage";
import { BrowserNavbar } from "./BrowserNavbar/BrowserNavbar";
import "./BrowserStyles.scss";
import { useUser } from "../common/contexts/userContext";
import { useFixtures } from "../common/contexts/fixturesContext";
import { useBets } from "../common/contexts/betContext";
import BrowserProfile from "./BrowserProfile/BrowserProfile";
import BrowserLogin from "./BrowserLogin/BrowserLogin";
import BrowserRanking from "./BrowserRanking/BrowserRanking";
import BrowserLeagues from "./BrowserLeagues/BrowserLeagues";
import BrowserFAQ from "./BrowserFAQ";
import BrowserFixture from "./BrowserFixture/BrowserFixture";
import BrowserBets from "./BrowserBets/BrowserBets";
import BrowserCreateAccount from "./BrowserWizard/BrowserCreateAccount";
import BrowserCreateLeague from "./BrowserWizard/BrowserCreateLeague";
import { jwtDecode } from "jwt-decode";
import { API_URL } from "../utils/api";
import { Match } from "../types/Match";
import { useEffect } from "react";
import ExcelData from "./ExcelData";

export const BrowserApp = () => {
  const { user, setUser } = useUser();
  const { setMatches } = useFixtures();
  const { setBets } = useBets();

  async function updateContexts(token: any) {
    const requestOptionFixtures = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
      },
    };
    const responseFixtures = await fetch(
      API_URL + `/api/Match/all`,
      requestOptionFixtures
    );
    const dataFixtures = await responseFixtures.json();
    // console.log(dataFixtures);

    setMatches(
      dataFixtures.filter((match: Match) => match.homeTeam && match.awayTeam)
    );
    // Bet Context
    const requestOptionBets = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
      },
    };
    const responseBets = await fetch(
      API_URL + `/api/Bet/All/User/${token.Id}`, // #TODO get user ID
      requestOptionBets
    );
    const dataBets = await responseBets.json();
    setBets(dataBets);

    const responseUser = await fetch(
      API_URL + `/api/User/${token.Id}`,
      requestOptionBets
    );
    const dataUser = await responseUser.json();
    setUser(dataUser);
    // navigate("/profile");
  }
  const isUserSigned = window.localStorage.getItem("isSigned") === "true";
  useEffect(() => {
    if (isUserSigned && !user.id) {
      const tokenDecoded = jwtDecode(
        window.localStorage.getItem("accessToken") || ""
      );
      updateContexts(tokenDecoded);
    }
  }, []);
  const isNavbarVisibile =
    !["/", "/login"].includes(window.location.pathname) || isUserSigned;
  return (
    <div className="main-body">
      {isNavbarVisibile && <BrowserNavbar />}
      <div style={{ height: "90vh" }}>
        <Routes>
          <Route
            path="/"
            element={isUserSigned ? <BrowserProfile /> : <BrowserLogin />}
          />
          <Route
            path="/homepage"
            element={isUserSigned ? <BrowserHomepage /> : <BrowserLogin />}
          />
          <Route
            path="/bets"
            element={isUserSigned ? <BrowserBets /> : <BrowserLogin />}
          />
          <Route
            path="/fixtures"
            element={isUserSigned ? <BrowserFixture /> : <BrowserLogin />}
          />
          <Route
            path="/ranking"
            element={isUserSigned ? <BrowserRanking /> : <BrowserLogin />}
          />
          <Route
            path="/profile"
            element={isUserSigned ? <BrowserProfile /> : <BrowserLogin />}
          />
          <Route
            path="/leagues"
            element={isUserSigned ? <BrowserLeagues /> : <BrowserLogin />}
          />
          <Route
            path="/faq"
            element={isUserSigned ? <BrowserFAQ /> : <BrowserLogin />}
          />
          <Route path='/data' element={<ExcelData />}/>
          <Route path="/login" element={<BrowserLogin />} />
          <Route path="/createAccount" element={<BrowserCreateAccount />} />
          <Route
            path="/createLeague"
            element={isUserSigned ? <BrowserCreateLeague /> : <BrowserLogin />}
          />
        </Routes>
      </div>
    </div>
  );
};
