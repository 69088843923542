import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./FixtureStyles.scss";
import { CircleFlag } from "react-circle-flags";
import CountryDict from "../../utils/countryDict";
import { GroupTableData } from "../../types/GroupTableData";
import { handleName } from "../../utils/handleName";

interface GroupTableProps {
  groupTableData: GroupTableData[];
  isDesktop?: boolean;
}

const GroupTable: React.FC<GroupTableProps> = ({
  groupTableData,
  isDesktop,
}) => {
  return (
    <TableContainer>
      <Table style={{ color: "white" }}>
        <TableHead>
          <TableRow>
            <TableCell
              className="fixtureTableCell"
              style={{
                width: "10px !important",
                padding: "16px 4px",
                textAlign: "right",
              }}
            ></TableCell>

            <TableCell
              className="fixtureTableCell"
              style={{
                width: isDesktop ? "200px" : "90px",
                fontSize: isDesktop ? "20px" : undefined,
              }}
            >
              Country
            </TableCell>
            <TableCell
              className="fixtureTableCell"
              style={{ fontSize: isDesktop ? "20px" : undefined }}
            >
              {" "}
              Points{" "}
            </TableCell>
            <TableCell
              className="fixtureTableCell"
              style={{
                padding: "16px 8px",
                fontSize: isDesktop ? "20px" : undefined,
              }}
            >
              {" "}
              Win
            </TableCell>
            <TableCell
              className="fixtureTableCell"
              style={{
                padding: "16px 8px",
                fontSize: isDesktop ? "20px" : undefined,
              }}
            >
              {" "}
              Draw{" "}
            </TableCell>
            <TableCell
              className="fixtureTableCell"
              style={{
                padding: "16px 8px",
                fontSize: isDesktop ? "20px" : undefined,
              }}
            >
              {" "}
              Loss{" "}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groupTableData.map((data, index) => (
            <TableRow key={`${data.team.internationalName}-row ${index}`}>
              <TableCell
                className="fixtureTableCell"
                style={{
                  padding: "16px 0px 16px 6px",
                  textAlign: "right",
                  fontSize: isDesktop ? "20px" : undefined,
                }}
              >
                {index + 1}.
              </TableCell>
              <TableCell
                className="fixtureTableCell"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  fontSize: isDesktop ? "20px" : undefined,
                }}
              >
                <div>
                  <CircleFlag
                    countryCode={
                      CountryDict.get(data.team.internationalName) || "pl"
                    }
                    style={{
                      height: isDesktop ? "30px" :"22px",
                      width: isDesktop ? "30px" : "22px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: isDesktop ? "22px" : undefined,
                    }}
                  />
                </div>

                <div>{isDesktop ? data.team.internationalName : handleName(data.team.internationalName)}</div>
              </TableCell>
              <TableCell
                className="fixtureTableCell"
                style={{ fontSize: isDesktop ? "20px" : undefined }}
              >
                {data.points}
              </TableCell>

              <TableCell
                className="fixtureTableCell"
                style={{
                  padding: "16px 8px",
                  fontSize: isDesktop ? "20px" : undefined,
                }}
              >
                {data.wins}
              </TableCell>
              <TableCell
                className="fixtureTableCell"
                style={{
                  padding: "16px 8px",
                  fontSize: isDesktop ? "20px" : undefined,
                }}
              >
                {data.draws}
              </TableCell>
              <TableCell
                className="fixtureTableCell"
                style={{
                  padding: "16px 8px",
                  fontSize: isDesktop ? "20px" : undefined,
                }}
              >
                {data.lost}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GroupTable;
