import React from "react";
import { MobileWizard } from "../../Mobile/MobileCreationWizard/MobileWizard";

const BrowserCreateAccount: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        color: "#FAFAFA",
        padding: "20px 40px",
        overflowY: "auto",
      }}
    >
      <MobileWizard isDesktop />
    </div>
  );
};

export default BrowserCreateAccount;
