import React from "react";
import Card from "@mui/material/Card";
import FixtureTileBetHeader from "./FixtureTileBetHeader";
import FixtureTileBody from "./FixtureTileBody";
import { Match } from "../../../types/Match";
import { Bet } from "../../../types/Bet";
import "./FixtureTile.scss";

export const TWO_HOURS = 2 * 60 * 60 * 1000;
export interface FixtureTileProps {
  match: Match;
  bet: Bet[];
  setCurrentModalMatch: React.Dispatch<React.SetStateAction<Match>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isDesktop?: boolean;
}

const FixtureTile: React.FC<FixtureTileProps> = ({
  match,
  bet,
  setCurrentModalMatch,
  setIsOpen,
  isDesktop,
}) => {
  const isBet = bet.length > 0;
  function handleClick(){
    setCurrentModalMatch(match);
    setIsOpen(true);
  }
  const isActive = new Date(match.kickOffTime?.dateTime || "").getTime() + TWO_HOURS > new Date().getTime();
  return (
    <Card
      style={{
        backgroundColor: "#172245",
        width: "100%",
        height: "100%",
        borderRadius: "20px",
      }}
      className={true ? "hover-fixture-tile": undefined}
      onClick={true ? () => handleClick() : undefined}
    >
      {isBet && (
        <FixtureTileBetHeader
        isDesktop={isDesktop}
          betAwayScore={bet[0].awayTeamScoreBet}
          betHomeScore={bet[0].homeTeamScoreBet}
        />
      )}
      <FixtureTileBody
        isBet={isBet}
        homeTeamName={match.homeTeam.internationalName || ""}
        awayTeamName={match.awayTeam.internationalName || ""}
        date={match.kickOffTime?.dateTime || ""}
        isPlayed={match.wasPlayed}
        match={match}
        isDesktop={isDesktop}
      />
    </Card>
  );
};

export default FixtureTile;
