import { Box, Typography } from "@mui/material";
import React from "react";
import { CircleFlag } from "react-circle-flags";
import countryDict from "../../utils/countryDict";

interface BetModalHeaderProps {
  homeCountryName: string;
  awayCountryName: string;
  isDesktop?: boolean;
}

const BetModalHeader: React.FC<BetModalHeaderProps> = ({
  homeCountryName,
  awayCountryName,
  isDesktop
}) => {
  return (
    <Box
      style={{
        width: "100%",
        fontSize: "24px",
        fontWeight: 600,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: isDesktop ? "120px" : '60px',
        paddingBottom: '10px',
        borderBottom: "1px solid #A97F4B",
      }}
    >
      {" "}
      <Box style={{ width: "30%", textAlign: "right" }}>
        {" "}
        <Typography fontSize={isDesktop ? "42px" : "26px"} letterSpacing={"1.5px"}>{isDesktop ? homeCountryName : homeCountryName.slice(0, 3).toUpperCase()}</Typography>
      </Box>
      <CircleFlag
        countryCode={countryDict.get(homeCountryName) || "Poland"}
        style={{ width: isDesktop ? "5%": "10%", marginLeft: isDesktop ? "30px" :'15px' }}
      />
      <Box style={{ width: isDesktop ?"10%" : "15%", textAlign: "center" }}><Typography fontSize={isDesktop ? "42px" : "26px"}>vs</Typography> </Box>
      <CircleFlag
        countryCode={countryDict.get(awayCountryName) || "Poland"}
        style={{ width: isDesktop ? "5%" : "10%",  marginRight: isDesktop ? "30px" :'15px' }}
      />
      <Box style={{ width: "30%", textAlign: "left",}}>
        {" "}
        <Typography fontSize={isDesktop ? "42px" : "26px"} letterSpacing={"1.5px"}>{isDesktop ? awayCountryName : awayCountryName.slice(0, 3).toUpperCase()}</Typography>
      </Box>
    </Box>
  );
};

export default BetModalHeader;
