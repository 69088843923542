import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
   <GoogleOAuthProvider clientId="160950947577-9k9660jdj152o9103tbsr22qgh8tbonh.apps.googleusercontent.com">
  {/* <GoogleOAuthProvider clientId="160950947577-ighnl1ras07n403e30vjlohcqd8ucv2v.apps.googleusercontent.com"> */}
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </GoogleOAuthProvider>
);
