import React from "react";

interface FixtureTileBetHeaderProps {
  betHomeScore: number;
  betAwayScore: number;
  isDesktop?: boolean;
}

const FixtureTileBetHeader: React.FC<FixtureTileBetHeaderProps> = ({
  betAwayScore,
  betHomeScore,
  isDesktop
}) => {
  return (
    <div
      style={{
        height: "20px",
        backgroundColor: "#F9BF4B",
        width: isDesktop ? "20%" : "33%",
        borderRadius: "15px",
        textAlign: "center",
      }}
    >
      {" "}
      Your bet {betHomeScore} - {betAwayScore}{" "}
    </div>
  );
};

export default FixtureTileBetHeader;
