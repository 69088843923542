import { Tab, Tabs } from "@mui/material";
import React, { useEffect, useRef } from "react";
import "./FixtureStyles.scss";

interface FixtureMenuProps {
  dayLabels: string[];
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  isDesktop?: boolean;
}

const FixtureMenu: React.FC<FixtureMenuProps> = ({
  dayLabels,
  value,
  setValue,
  isDesktop,
}) => {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!isDesktop) {
      setValue(6);
      setTimeout(() => setValue(4), 100);
    }
    // centering in very specifc way... xD
  }, [setValue]);
  return (
    <Tabs
      className="styledTabs"
      onChange={handleChange}
      value={value}
      ref={ref}
      variant="scrollable"
      scrollButtons={false}
      aria-label="scrollable prevent tabs example"
    >
      {dayLabels.map((dayLabel, index) => (
        <Tab
          label={dayLabel}
          key={`${dayLabel}-tab`}
          style={{
            color: value === index ? "#FDFDFD" : "#A2AEBB",
            textTransform: "none",
            fontWeight: "600",
            width: isDesktop ? "120px" : undefined,
          }}
        />
      ))}
    </Tabs>
  );
};

export default FixtureMenu;
