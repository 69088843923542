import React, { useEffect, useState } from "react";
import MobileBetsFilterPanel from "../../Mobile/MobileBets/MobileBetsFilterPanel";
import { BetFilter } from "../../Mobile/MobileBets/MobileBets";
import { useBets } from "../../common/contexts/betContext";
import { Bet } from "../../types/Bet";
import BrowserBetsBody from "./BrowserBetsBody";
import { CircularProgress } from "@mui/material";

const BrowserBets: React.FC = () => {
  const [currentFilters, setCurrentFilters] = useState<BetFilter[]>([]);
  function onFilterClick(value: BetFilter) {
    if (!currentFilters.includes(value)) {
      setCurrentFilters((prev) => [...prev, value]);
    } else {
      setCurrentFilters((prev) => prev.filter((filter) => filter !== value));
    }
  }
  const { bets } = useBets();
  const [betsCurrent, setBetsCurrent] = useState<Bet[]>(bets);
  useEffect(() => {
    let filteredBets = bets;
    if (currentFilters.includes("Correct")) {
      filteredBets = filteredBets.filter(
        (bet) => bet.betResult === 1 || bet.betResult === 2
      );
    }
    if (currentFilters.includes("Wrong")) {
      filteredBets = filteredBets.filter((bet) => bet.betResult === 0);
    }

    if (
      !(currentFilters.includes("Past") && currentFilters.includes("Ongoing"))
    ) {
      if (currentFilters.includes("Past")) {
        filteredBets = filteredBets.filter((bet) => !bet.isActive);
      }
      if (currentFilters.includes("Ongoing")) {
        filteredBets = filteredBets.filter((bet) => bet.isActive);
      }
    }

    setBetsCurrent(filteredBets);
  }, [bets, currentFilters]);
  return (
    <>
      {bets.length === 0 ? (
        <CircularProgress />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
          }}
        >
          <MobileBetsFilterPanel
            currentFilters={currentFilters}
            onFilterClick={onFilterClick}
            isDesktop
          />

          <div
            style={{
              height: "100%",
              overflowY: "auto",
            }}
          >
            <BrowserBetsBody bets={betsCurrent} />
          </div>
        </div>
      )}
    </>
  );
};

export default BrowserBets;
