import { Typography } from "@mui/material";

export const BrowserHomepage = () => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Typography
        variant="h4"
        fontFamily={"monospace"}
        style={{ color: "#FAFAFA", paddingTop: "40px", textAlign: 'center' }}
      >
        {" "}
        Soon you will be able to see here personalized content <br/> connected with
        Euro 2024.{" "}
      </Typography>
    </div>
  );
};
