import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, Typography } from "@mui/material";
import { useUser } from "../../common/contexts/userContext";
import { API_URL } from "../../utils/api";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { League } from "../../types/League";
import { truncateString } from "../../utils/textUtils";
import { Info } from "@mui/icons-material";
import { getLeagueRequests } from "./ManageLeaguePanel";
import { RequestLeague } from "../../types/RequestLeague";
import debounce from "debounce";
import { useAllUsers } from "../../common/contexts/allUsersContext";

interface LeagueInfoProps {
  currentLeague: League | undefined;
  width?: string;
  buttonHeight?: string;
  truncateValue?: number;
  height?: string;
  isDesktop?: boolean;
}

const LeagueInfo: React.FC<LeagueInfoProps> = ({
  currentLeague,
  width,
  buttonHeight,
  truncateValue,
  height,
  isDesktop,
}) => {
  const { user } = useUser();
  const {users} = useAllUsers();
  const admin = users.filter(item => item.id === currentLeague?.adminId)[0];
  const [userRequests, setUserRequests] = useState<RequestLeague[]>([]);
  const showButton = !(
    user.leagues.filter((league) => league.id === currentLeague?.id)?.length > 0
  );

  const [label, setLabel] = useState("");

  function sendUserJoinRequest() {
    const token = window.localStorage.getItem("accessToken");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ userId: user.id, leagueId: currentLeague?.id }),
    };
    fetch(API_URL + `/api/League/Request/Join`, requestOptions).then(
      (response) =>
        response.ok === true
          ? setLabel("Request sent")
          : alert("Something went wrong :( ")
    );
  }

  useEffect(() => {
    if (currentLeague) {
      const response = getLeagueRequests(currentLeague?.id);
      response.then((data) => setUserRequests(data));
    }
  }, [currentLeague]);

  useEffect(() => {
    setLabel(
      userRequests.filter((item) => item.userId === user.id)?.length > 0
        ? "Request sent"
        : "Join League"
    );
  }, [userRequests]);
  return (
    <Card
      style={{
        minHeight: height || "400px",
        width: width ? width : "90%",
        borderRadius: "10px",
        marginBottom: "20px",
        border: "2px solid #111",
        background: "#454758",
        overflowY: 'auto',

      }}
    >
      <CardContent
        style={{
          color: "#FAFAFA",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "92%",
        }}
      >
        {" "}
        <Typography
          variant={isDesktop ? "h3" : "h5"}
          style={{
            color: "#FAFAFA",
            justifyContent: "center",
            display: "flex",
            fontWeight: "600",
            letterSpacing: "2px",
          }}
        >
          {truncateString(
            truncateValue ? truncateValue * 2 : 20,
            currentLeague?.name
          )}
        </Typography>{" "}
        <div
          style={{
            margin: "20px 0",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            width: "100%",
            height: "100%",
            gap: "20px",
          }}
        >
          <Card
            style={{
              background: "#404050",
              display: "flex",
              alignItems: "center",
              color: "#E6E7EA",
              flexDirection: "column",
              minHeight: "110px",
            }}
          >
            <Typography
              variant={isDesktop ? "h4" : "h5"}
              style={{ paddingTop: "20px" }}
            >
              Founder
            </Typography>
            <Typography
              style={{
                fontSize: isDesktop ? "36px" : "22px",
                fontWeight: "600",
                height: "100%",
                display: "flex",
                marginTop: "10px",
              }}
            >
              {" "}
              {truncateString(
                truncateValue || 10,
                admin?.username
              )}
            </Typography>
          </Card>
          <Card
            style={{
              background: "#404050",
              display: "flex",
              alignItems: "center",
              color: "#E6E7EA",
              flexDirection: "column",
              minHeight: "110px",
            }}
          >
            {" "}
            <Typography
              variant={isDesktop ? "h4" : "h5"}
              style={{ paddingTop: "20px" }}
            >
              Leader
            </Typography>
            <Typography
              style={{
                fontSize: isDesktop ? "36px" : "22px",
                fontWeight: "600",
                height: "100%",
                marginTop: "10px",
                display: "flex",
              }}
            >
              {" "}
              {truncateString(truncateValue || 10, currentLeague?.topScorer)}
            </Typography>
          </Card>
          <Card
            style={{
              background: "#404050",
              display: "flex",
              alignItems: "center",
              color: "#E6E7EA",
              flexDirection: "column",
              minHeight: "120px",
            }}
          >
            <Typography
              variant={isDesktop ? "h4" : "h5"}
              style={{ paddingTop: "20px" }}
            >
              Players
            </Typography>
            <Typography
              style={{
                fontSize: "38px",
                fontWeight: "600",
                letterSpacing: "2.5px",
                height: "100%",
                display: "flex",
              }}
            >
              {currentLeague?.usersCount}
            </Typography>
          </Card>
          <Card
            style={{
              background: "#404050",
              display: "flex",
              alignItems: "center",
              color: "#E6E7EA",
              flexDirection: "column",
              minHeight: "120px",
            }}
          >
            {" "}
            <Typography
              variant={isDesktop ? "h4" : "h5"}
              style={{ paddingTop: "20px" }}
            >
              Top Score
            </Typography>
            <Typography
              style={{
                fontSize: "36px",
                fontWeight: "600",
                letterSpacing: "2.5px",
                height: "100%",
                display: "flex",
              }}
            >
              {currentLeague?.topPoints}pts
            </Typography>
          </Card>
        </div>
        <div style={{ display: "flex", justifyContent: "right" }}>
          {showButton && (
            <Button
              endIcon={
                label !== "Join League" ? <CheckOutlinedIcon /> : undefined
              }
              style={{
                color: "#061237",
                fontWeight: "600",
                background: "#40E0D0",
                borderRadius: "5px",
                width: "100%",
                height: buttonHeight,
              }}
              disabled={label !== "Join League"}
              onClick={debounce(sendUserJoinRequest, 300)}
            >
              {" "}
              {label}{" "}
            </Button>
          )}
        </div>
        {isDesktop && currentLeague?.description && (
          <Typography
            fontSize={"18px"}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              color: "#FAFAFA",
              marginTop: "10px",
            }}
          >
            {" "}
            <Info />
            {currentLeague?.description}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default LeagueInfo;
