let CountryDict = new Map<string, string>();

CountryDict.set("Netherlands", "nl");
CountryDict.set("Poland", "pl");
CountryDict.set("England", "gb-eng");
CountryDict.set("France", "fr");
CountryDict.set("Germany", "de");
CountryDict.set("Spain", "es");
CountryDict.set("Croatia", "hr");
CountryDict.set("Belgium", "be");
CountryDict.set("Switzerland", "ch");
CountryDict.set("Serbia", "rs");
CountryDict.set("Portugal", "pt");
CountryDict.set("Denmark", "dk");
CountryDict.set("Scotland", "gb-sct");
CountryDict.set("Türkiye", "tr");
CountryDict.set("Austria", "at");
CountryDict.set("Hungary", "hu");
CountryDict.set("Slovakia", "sk");
CountryDict.set("Albania", "al");
CountryDict.set("Romania", "ro");
CountryDict.set("Czechia", "cz");
CountryDict.set("Italy", "it");
CountryDict.set("Slovenia", "si");
CountryDict.set("Georgia", "ge");
CountryDict.set("Ukraine", "ua");

export default CountryDict;
