import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import "./MobileProfile.scss";
import React from "react";
import UploadPhoto from "./UploadPhoto";
import { useUser } from "../../common/contexts/userContext";
import { User } from "../../types/User";
import { League } from "../../types/League";
import BetOutcomeIcon from "../components/BetOutcomeIcon";
import { useNavigate } from "react-router-dom";

export const MobileProfile = () => {
  const { user } = useUser();
  return (
    <>
      {user.lastFiveBets?.length === 0 ||
      user.username === "" ||
      user.leagues.length === 0 ? (
        <CircularProgress />
      ) : (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            overflowY: "auto",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
            padding: "25px 0",
          }}
        >
          <Card
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "25px",
              width: "90%",
              background: "#172245",
              color: "#FAFAFA",
              height: "100%",
              // overflowY: "hidden",
              border: "2px solid #EAEADA",
              paddingBottom: "20px",
            }}
          >
            <Typography
              style={{
                fontWeight: 600,
                fontSize: "1.5em",
                color: "#FAFAFA",
                margin: "10px 0",
              }}
            >
              {" "}
              Hi {user.username}!{" "}
            </Typography>

            <UploadPhoto buttonLabel="Upload a profile picture" />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
                width: "100%",
                padding: "20px 0",
                height: "100%",
              }}
            >
              <Box
                sx={{ minWidth: 95, display: "flex", justifyContent: "center" }}
                className="cardProfile"
              >
                <Card
                  variant="outlined"
                  style={{
                    width: "90%",
                    background: "#EAEADA",
                    marginTop: "20px",
                  }}
                >
                  {userBets(user)}
                </Card>
              </Box>

              <Box
                sx={{
                  minWidth: 95,
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
                className="cardProfile"
              >
                <Card
                  variant="outlined"
                  style={{
                    width: "90%",
                    height: "100%",
                    background: "#EAEADA",
                    overflowY: "auto",
                    color: "#111",
                  }}
                >
                  {positionLeagues(user)}
                </Card>
              </Box>
            </div>
          </Card>
        </div>
      )}
    </>
  );
};

const userBets = (user: User) => (
  <React.Fragment>
    <CardContent className="cardProfile">
      <Typography variant="h5" component="div">
        Results
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "90%",
        }}
      >
        <Box style={{ paddingRight: "20px" }}>
          <Typography sx={{}}>Your points</Typography>
          <Typography style={{ fontSize: "24px" }}>
            {" "}
            {user.score.points} pts
          </Typography>
        </Box>
        <Box>
          <Typography sx={{}}>Last 5 bets</Typography>
          <div>
            {" "}
            {user.lastFiveBets?.map((item, index) => (
              <BetOutcomeIcon outcome={item} key={`${index}-beticon-profile`} />
            ))}
          </div>
        </Box>
      </div>
    </CardContent>
  </React.Fragment>
);

const positionLeagues = (user: User) => (
  <React.Fragment>
    <CardContent className="cardProfile">
      <Typography variant="h5" component="div">
        Leagues
      </Typography>
      <div>
        {user.leagues.map((league) => (
          <LeagueTile league={league} key={`${league.id} - leagues-profile`} />
        ))}
      </div>
    </CardContent>
  </React.Fragment>
);

const LeagueTile: React.FC<{ league: League }> = ({ league }) => {
  const navigate = useNavigate();
  function handleClick(league: League) {
    navigate("/ranking", {
      state: { leagueOption: { label: league.name, leagueId: league.id } },
    });
  }
  return (
    <div
      style={{
        width: "90%",
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #525252",
        alignItems: "center",
        marginBottom: "10px",
      }}
    >
      <Typography fontFamily={"serif"}>{league.name}</Typography>
      <Button style={{}} onClick={() => handleClick(league)}>
        Check{" "}
      </Button>
    </div>
  );
};
