import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import "../MobileApp.scss";
import { Avatar, Typography } from "@mui/material";
import { truncateString } from "../../utils/textUtils";
import { RankingData } from "../../types/RankingData";
import BetOutcomeIcon from "../components/BetOutcomeIcon";
import { useAllUsers } from "../../common/contexts/allUsersContext";
import { useUser } from "../../common/contexts/userContext";

interface MobileRankingTableProps {
  leagueRankingData: RankingData[];
  isDesktop?: boolean;
}

const MobileRankingTable: React.FC<MobileRankingTableProps> = ({
  leagueRankingData,
  isDesktop,
}) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const { users } = useAllUsers();
  const { user } = useUser();
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns: Column[] = [
    { id: "position", label: "", minWidth: 0, align: "right" },
    { id: "username", label: "Player", minWidth: isDesktop ? 40 : 0 },
    { id: "points", label: "Points", minWidth: 15 },
    {
      id: "perfectBets",
      label: isDesktop ? "Perfect Bets" : "PB",
      minWidth: 15,
      // align: "right",
      format: (value: number) => value.toLocaleString("en-US"),
    },
    {
      id: "goodBets",
      label: isDesktop ? "Good Bets" : "GB",
      minWidth: 15,
      // align: "right",
      format: (value: number) => value.toLocaleString("en-US"),
    },
    {
      id: "wrongBets",
      label: isDesktop ? "Wrong Bets" : "WB",
      minWidth: 15,
      // align: "right",
      format: (value: number) => value.toLocaleString("en-US"),
    },
  ];

  useEffect(() => {
    setPage(0);
  }, [leagueRankingData])
  return (
    <Paper sx={{ width: "93%", overflowY: "auto", background: "#454758" }}>
      <TableContainer style={{}}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={
                    isDesktop
                      ? undefined
                      : (column.align as
                          | "center"
                          | "left"
                          | "right"
                          | "inherit"
                          | "justify"
                          | undefined)
                  }
                  style={{
                    minWidth: column.minWidth,
                    background: "#F9BF4B",
                    fontWeight: "600",
                    fontSize: isDesktop ? "18px" : undefined,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
              {isDesktop && (
                <TableCell
                  style={{
                    background: "#F9BF4B",
                    fontWeight: "600",
                    fontSize: isDesktop ? "18px" : undefined,
                  }}
                >
                  Last 5 bets
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {leagueRankingData
              ?.sort((a, b) => a.rankPlace - b.rankPlace)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isUser = row.userId === user.id;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={`row ranking ${index}`}
                    style={{
                      fontWeight: isUser ? "600" : undefined,
                      background: isUser ? '#656778' : undefined,
                      borderColor: 'green'
                    }}
                  >
                    <TableCell
                      style={{
                        color: "#B6B7BA",
                        textAlign: isDesktop ? "right" : undefined,
                        fontWeight: isUser ? "600 " : undefined,
                      }}
                    >
                      {" "}
                      {row.rankPlace === 1 ? <FirstPlace /> : `${row.rankPlace}.`}
                    </TableCell>
                    <TableCell style={{ color: "#B6B7BA" }}>
                      {" "}
                      <Typography
                        noWrap
                        sx={{
                          wordWrap: "break-word",
                          fontSize: "14px",
                          fontWeight: isUser ? "600" : undefined,
                        }}
                        style={
                          isDesktop
                            ? {
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }
                            : undefined
                        }
                      >
                        {isDesktop && (
                          <Avatar
                            src={row.avatarUrl}
                            style={{ height: "35px", width: "35px" }}
                          />
                        )}
                        {isDesktop
                          ? truncateString(25, row.username)
                          : truncateString(9, row.username)}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#B6B7BA",
                        fontWeight: isUser ? "600" : undefined,
                      }}
                    >
                      {" "}
                      {row.points}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#B6B7BA",
                        fontWeight: isUser ? "600" : undefined,
                      }}
                    >
                      {" "}
                      {row.perfectBets}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#B6B7BA",
                        fontWeight: isUser ? "600" : undefined,
                      }}
                    >
                      {" "}
                      {row.correctBets}
                    </TableCell>
                    <TableCell
                      style={{
                        color: "#B6B7BA",
                        fontWeight: isUser ? "600" : undefined,
                      }}
                    >
                      {" "}
                      {row.wrongBets}
                    </TableCell>
                    {isDesktop && (
                      <TableCell style={{ color: "#B6B7BA" }}>
                        {" "}
                        {users
                          .filter((item) => item.id === row.userId)[0]
                          ?.lastFiveBets?.map((item, index) => (
                            <BetOutcomeIcon
                              outcome={item}
                              key={`${index}-beticon-profile`}
                            />
                          ))}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={leagueRankingData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        style={{ background: "#454758 !important" }}
        className="table-bottom"
      />
    </Paper>
  );
};

export default MobileRankingTable;

interface Column {
  id:
    | "username"
    | "points"
    | "perfectBets"
    | "goodBets"
    | "wrongBets"
    | "position";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const FirstPlace = () => {
  return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
      <img src="./images/crown_trans.png" height={24} style={{paddingRight: '10px'}} alt="crown"/>
      {1}.
  </div>
}