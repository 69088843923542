export const API_URL =
  process.env.REACT_APP_IS_IT_PRODUCTION_VERSION === "false"
    ? process.env.REACT_APP_API_URL_LOCAL
    : (process.env.REACT_APP_IS_IT_PRODUCTION_VERSION === undefined ? 'https://eurotyper2024v1-api-wa-dev.azurewebsites.net' : process.env.REACT_APP_API_URL_PROD)


// console.log("process.env.NODE_ENV", process.env.NODE_ENV)
// console.log("API_URL", API_URL)
// console.log("process.env.REACT_APP_IS_IT_PRODUCTION_VERSION", process.env.REACT_APP_IS_IT_PRODUCTION_VERSION)
// console.log("process.env.REACT_APP_API_URL_PROD", process.env.REACT_APP_API_URL_PROD)
// console.log("process.env.REACT_APP_API_URL_LOCAL", process.env.REACT_APP_API_URL_LOCAL)

const sendGetRequest = (path: string) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  fetch(API_URL + path, requestOptions)
    .then((response) => {
      // console.log("response: ", response);
      if (response.ok) {
        return response.json();
      }
    })
    .then((data) => {
      // console.log("data: ", data);
      return data;
    });
};

export async function getUserByID(userId: number) {
  const data = await sendGetRequest(`/api/User/${userId}`);
  return data;
}
