import React, { useEffect, useState } from "react";
import MobileRankingTable from "../../Mobile/MobileRanking/MobileRankingTable";
import { RankingData } from "../../types/RankingData";
import LeaguesDropdown, {
  LeagueDescription,
  getGeneralLeague,
} from "../../Mobile/components/LeaguesDropdown";
import { useLocation } from "react-router-dom";
import {
  fetchLeagueInfo,
  formatDateUpdate,
  getLastUpdate,
} from "../../Mobile/MobileRanking/MobileRanking";
import { useUser } from "../../common/contexts/userContext";
import { League } from "../../types/League";
import { Avatar, CircularProgress, Typography } from "@mui/material";
import { API_URL } from "../../utils/api";
import { AccessTimeOutlined } from "@material-ui/icons";

interface BrowserRankingProps {}

const BrowserRanking: React.FC<BrowserRankingProps> = () => {
  const { state } = useLocation();
  const { user } = useUser();
  const [currentLeagueAvatar, setCurrentLeagueAvatar] = useState("");
  const [lastUpdate, setLastUpdate] = useState("");

  const [initLeagueDescription, setInitLeagueDescription] =
    useState<LeagueDescription>({ leagueId: -1, label: "General" });
  const stylesLeaguesDropdown = { marginBottom: "20px" };
  const [leagueToDisplay, setLeagueToDisplay] = useState<LeagueDescription>(
    state && state.leagueOption ? state.leagueOption : initLeagueDescription
  );
  const [rankingData, setRankingData] = useState<RankingData[]>([]);

  let isLoading = rankingData.length === 0 || lastUpdate === "";

  useEffect(() => {
    if (
      initLeagueDescription.leagueId !== -1 ||
      (state && state.leagueOption)
    ) {
      const response = fetchLeagueInfo(leagueToDisplay.leagueId);
      response.then((data) =>
        setRankingData(data.rankingRows as RankingData[])
      );
      fetchLeagueById(leagueToDisplay.leagueId).then((data) =>
        setCurrentLeagueAvatar(data.leagueImageUrl)
      );
    } else {
      getGeneralLeague().then((data: any) => {
        setLeagueToDisplay({ leagueId: data.id, label: "General" });
        setInitLeagueDescription({ leagueId: data.id, label: "General" });
      });
    }
  }, [leagueToDisplay, initLeagueDescription]);

  useEffect(() => {
    getLastUpdate().then((data) => setLastUpdate(data));
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          width: "80%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          padding: "40px 0",
        }}
      >
        <LeaguesDropdown
          setCurrentLeague={setLeagueToDisplay}
          styles={stylesLeaguesDropdown}
          leagueOptions={user.leagues.map((item: League) => ({
            label: item.name,
            leagueId: item.id,
          }))}
          currentLeague={leagueToDisplay.label}
          width="80%"
        />
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <div
              style={{
                display: "flex",
                // alignItems: "center",
                // gap: "15px",
                width: "93%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                {currentLeagueAvatar && (
                  <Avatar
                    src={`${currentLeagueAvatar}`}
                    style={{ marginRight: "10px" }}
                  />
                )}
                <Typography
                  variant="h4"
                  style={{
                    color: "#FAFAFA",
                    // textAlign: "left",
                    width: "100%",
                    margin: "20px 0",
                  }}
                >
                  {" "}
                  {`${leagueToDisplay.label} League`}
                </Typography>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "flex-end",
                  margin: "20px 0",
                }}
              >
                <Typography
                  style={{
                    width: "100%",
                    color: "#B6B7BA",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: "5px",
                  }}
                >
                  <AccessTimeOutlined />
                  Updated: {formatDateUpdate(new Date(lastUpdate))}
                </Typography>
              </div>
            </div>
            <MobileRankingTable leagueRankingData={rankingData} isDesktop />
          </>
        )}
      </div>
    </div>
  );
};

export default BrowserRanking;

export async function fetchLeagueById(leagueId: number) {
  const requestOption = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${window.localStorage.getItem("accessToken")}`,
    },
  };
  const response = await fetch(
    API_URL + `/api/League/${leagueId}`,
    requestOption
  );
  const data = await response.json();
  // console.log(data);
  return data;
}
